
export enum WORKSPACE_ACCESS_RIGHT {
  OWNER = 12,
  ADMIN = 8,
  WRITE = 4,
  READ = 2,
	NONE = 0,
}

export interface IWorkspace {
	id: number;
	name: string;
	description: string;
	image?: string;
	language: string;
	audienceAge: number;
	isPersonal: boolean;
	rightLevel: WORKSPACE_ACCESS_RIGHT;
	ownerId: number;
	createdAt: Date;
	updatedAt: Date;	
}


export interface IWorkspaceWithOwner {
	id: number;
	name: string;
	description: string;
	image?: string;
	language: string;
	audienceAge: number;
	isPersonal: boolean;
	isPublic: boolean;
	rightLevel: WORKSPACE_ACCESS_RIGHT;
	ownerId: number;
	ownerName: string;
	ownerImage: string;
	elements: number;
	relations: number;
	narratives: number;
	members: number;
	createdAt: Date;
	updatedAt: Date;	
}


export interface IAccessRight  {
	id: number;
	rightLevel: WORKSPACE_ACCESS_RIGHT;
	userId: number;
	userName: string;
	userImage: string;
	userFriendCode: string;
	createdAt: Date;
	updatedAt: Date;	
}

