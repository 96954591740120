// ----------------------------------------------------------------------

const fr = {
	// EN-TÊTE DE NAVIGATION PRINCIPALE
	nav: {
		home: "Accueil",
		who_it_is_for: "À qui cela s'adresse",
		who_we_are: "Qui nous sommes",
		where_to_find_us: "Où nous trouver",
		dashboard: "Tableau de bord",
		login: "Connexion",
		sign_up_for_free: "Inscription gratuite",
		need_help: "Besoin d'aide?",
		workspace_list: "Liste des espaces de travail",
	},
	// AUTHENTIFICATION
	auth: {
		register: {
			page_title: "S'inscrire",
			signup_for_free: "Inscription gratuite",
			main_slogan: "Explorez, Apprenez, Comprenez",
			get_started_for_free: "Commencez gratuitement.",
			already_an_account: "Vous avez déjà un compte?",
			signin: "Se connecter",
			by_signin_i_agree: "En m'inscrivant, j'accepte les ",
			tos: "Conditions d'utilisation",
			privacy_policy: "Politique de confidentialité",
			form: {
				label: {
					firstName: "Prénom",
					lastName: "Nom",
					email: "Adresse e-mail",
					password: "Mot de passe",
				},
				error: {
					first_name_required: "Prénom requis",
					first_name_pattern:
						"Le prénom doit commencer par une lettre et ne peut contenir aucun caractère spécial",
					last_name_required: "Nom requis",
					last_name_pattern:
						"Le nom doit commencer par une lettre et ne peut contenir aucun caractère spécial",
					email_required: "L'e-mail est requis",
					email_valid: "L'e-mail doit être une adresse valide",
					password_required: "Mot de passe requis",
					password_min_length: "Le mot de passe doit comporter au moins 8 caractères",
					password_pattern:
						"Le mot de passe doit contenir au moins 8 caractères, un chiffre, une lettre et un caractère spécial",
				},
				create_account_button: "Créer un compte",
			},
		},
		verify_code: {
			page_title: "Vérifier le code",
			main_title: "Veuillez vérifier votre e-mail !",
			explanation_text:
				"Nous avons envoyé un code de confirmation à 6 caractères à {{email}}, veuillez entrer le code ci-dessous pour vérifier votre e-mail.",
			return_home: "Retourner à l'accueil",
			form: {
				error: {
					invalid_code: "Ce code est invalide",
					code_required: "Le code est requis",
					no_user_info:
						"Il semble que nous avons perdu vos informations, veuillez recommencer la procédure d'inscription",
				},
				verify_code_button: "Vérifier",
				verify_success_snackbar: "Vérification réussie!",
			},
		},
		login: {
			page_title: "Connexion",
			go_to_app: "Se connecter",
			main_slogan: "Bonjour, bienvenue à nouveau",
			signin: "Se connecter à WISER.LY",
			new_user: "Nouvel utilisateur?",
			create_account_link: "Créer un compte",
			forgot_password_link: "Mot de passe oublié?",
			activate_link: "Activer le compte?",
			form: {
				label: {
					email: "Adresse e-mail",
					password: "Mot de passe",
				},
				error: {
					email_required: "L'e-mail est requis",
					email_valid: "L'e-mail doit être une adresse valide",
					password_required: "Mot de passe requis",
					invalid_credentials: "Identifiants de connexion invalides. Veuillez réessayer.",
					user_not_activated: "Vous n'avez pas encore activé votre compte, vérifiez votre e-mail",
				},
				login_button: "Connexion",
			},
		},
		forgot_password: {
			page_title: "Réinitialiser le mot de passe",
			main_title: "Mot de passe oublié?",
			explanation_text:
				"Veuillez entrer l'adresse e-mail associée à votre compte et nous vous enverrons un lien pour réinitialiser votre mot de passe.",
			return_to_signin: "Retour à la connexion",
			form: {
				label: {
					email: "Adresse e-mail",
				},
				error: {
					email_required: "L'e-mail est requis",
					email_valid: "L'e-mail doit être une adresse valide",
					user_does_not_exist: "Nous n'avons pas de compte associé à cet e-mail",
				},
				send_request_button: "Envoyer la demande",
			},
		},
		new_password: {
			page_title: "Nouveau mot de passe",
			main_title: "Demande envoyée avec succès!",
			explanation_text:
				"Nous avons envoyé un code de confirmation à 6 caractères à votre e-mail. Veuillez entrer le code ci-dessous pour vérifier votre e-mail, puis créer votre nouveau mot de passe.",
			return_to_signin: "Retour à la connexion",
			form: {
				label: {
					email: "Adresse e-mail",
					password: "Nouveau mot de passe",
					confirm_password: "Confirmer le nouveau mot de passe",
				},
				error: {
					email_required: "L'e-mail est requis",
					email_valid: "L'e-mail doit être une adresse valide",
					code_required: "Le code est requis",
					password_required: "Mot de passe requis",
					confirm_password_required: "La confirmation du mot de passe est requise",
					password_pattern:
						"Le mot de passe doit contenir au moins 8 caractères, un chiffre, une lettre et un caractère spécial",
					passwords_must_match: "Les mots de passe doivent correspondre",
					unauthorized: "Nous ne pouvons pas autoriser la demande, avez-vous vérifié l'e-mail?",
					reset_period_expired:
						"Le code que vous avez utilisé a été envoyé il y a trop longtemps, pour des raisons de sécurité, il n'est valable que 10 minutes. Veuillez recommencer la procédure.",
					reset_code_invalid: "Ce code est invalide",
				},
				update_password_button: "Mettre à jour le mot de passe",
				update_success_snackbar: "Changement de mot de passe réussi!",
			},
		},
	},
	// PAGE PUBLIQUE
	public_page: {
		footer: {
			all_rights_reserved: "© Tous droits réservés",
			all_rights_reserved_2023: "© 2023. Tous droits réservés",
			made_by: "réalisé par",
			hero_text:
				"Réalisez tout votre potentiel de connaissance avec notre plateforme facile à utiliser, qui vous permet de transformer rapidement vos informations en connaissances précieuses.",
		},
		home: {
			page_title: "Découvrez l'Histoire avec WISER.LY",
			hero: {
				main_text: "Découvrez l'Histoire<br/>avec WISER.LY",
				secondary_text: "Explorez, Apprenez, Comprenez.",
				cta: "Essayez gratuitement",
			},
			title: {
				big_title: "Là où l'Histoire prend vie",
				overline: "Maîtrisez l'essentiel",
				stories: {
					ancient_egypt: {
						title: "Égypte ancienne",
						description: "Des Pharaons aux Pyramides",
					},
					ancient_philosophers: {
						title: "Philosophes de l'Antiquité",
						description: "De Thalès à Cicéron",
					},
					roman_empire: {
						title: "Empire romain",
						description: "Montée, Expansion, et Chute",
					},
					middle_ages: {
						title: "Moyen Âge",
						description: "Du Féodalisme à la Peste Noire",
					},
					new_world: {
						title: "Découverte du Nouveau Monde",
						description: "Explorateurs et Conquêtes",
					},
					renaissance: {
						title: "Renaissance",
						description: "La Renaissance de l'Art et des Sciences",
					},
					enlightment: {
						title: "Le Siècle des Lumières",
						description: "Philosophes, Idées, et Changements Sociaux",
					},
					french_revolution: {
						title: "Révolution française de 1789",
						description: "Acteurs, Événements, et Lieux Importants",
					},
					industrial_revolution: {
						title: "Révolution industrielle",
						description: "Machines, Usines, et Impact Social",
					},
					first_world_war: {
						title: "Première Guerre mondiale",
						description: "Causes, Batailles, et Conséquences",
					},
					second_world_war: {
						title: "Seconde Guerre mondiale",
						description: "De la Blitzkrieg à Hiroshima",
					},
					cold_War: {
						title: "Guerre froide",
						description: "Conflit Idéologique et Tensions Mondiales",
					},
					internet: {
						title: "Histoire de l'Internet",
						description: "D'ARPANET aux Réseaux Sociaux",
					},
					great_inventions: {
						title: "Grandes Inventions",
						description: "De la Roue au Smartphone",
					},
					european_union: {
						title: "Union Européenne",
						description: "Du Charbon et de l'Acier à une Europe Unie",
					},
				},
			},
			argument1: {
				overline: "Simplifié, Attrayant, Efficace",
				title: "Apprenez l'Histoire facilement",
				description:
					"WISER.LY simplifie l'apprentissage de l'histoire avec des leçons claires et des outils interactifs. Comprenez rapidement les concepts clés et retenez-les plus longtemps.<br/>Qu'il s'agisse de dates ou d'événements, WISER.LY rend l'apprentissage facile et agréable.",
			},
			argument2: {
				overline: "Des histoires qui donnent vie à l'Histoire",
				title: "L'Histoire comme un récit",
				description:
					"WISER.LY transforme l'histoire en récits captivants, facilitant ainsi la connexion avec le passé et la mémorisation de ce qui est vraiment important.",
			},
			argument3: {
				overline: "Le contexte à portée de main",
				title: "Explorez et comprenez",
				description:
					"Plongez profondément dans l'histoire avec des outils visuels puissants qui donnent vie au passé. Explorez des chronologies interactives, des cartes géographiques et des graphes relationnels pour voir comment les événements, les lieux et les personnes sont interconnectés.<br/>Les visualisations de WISER.LY rendent les données historiques complexes faciles à explorer, vous aidant à saisir à la fois la vue d'ensemble et les détails avec clarté.",
				carousel: {
					img_1: "Visualisez de manière complète avec la GeoMap | WISER.LY",
					img_2: "Visualisez de manière complète avec la Vue des Éléments | WISER.LY",
					img_3: "Visualisez de manière complète avec le Graphique des Relations | WISER.LY",
					img_4: "Visualisez de manière complète avec les Chronologies | WISER.LY",
				},
			},
			argument4: {
				overline: "Éléments dynamiques et rapports",
				title: "Partagez vos découvertes",
				description:
					"Mettez en évidence les éléments souhaités, décrivez les relations intéressantes et donnez vos conclusions dans un format lisible et facile à comprendre. Vous pouvez également explorer chaque élément de vos rapports!",
			},
			argument_final: {
				title: "Partagez l'Histoire et la Culture avec les Prochaines Générations",
				description:
					"Rejoignez-nous pour préserver et transmettre le savoir qui façonne notre monde.<br/>Que vous soyez <strong>étudiant, enseignant ou passionné d'histoire</strong>, votre contribution aide à construire un avenir plus riche et mieux informé.",
				cta: "Demandez une démonstration",
			},
			pricing: {
				overline: "Plans tarifaires",
				title_1: "Le bon plan pour",
				title_2: "votre entreprise",
				description: "Choisissez le plan parfait pour vos besoins. Toujours flexible pour grandir",
			},
			advertisement: {
				title: "Commencez dès aujourd'hui<br/>avec WISER.LY",
				cta: "Rejoignez la communauté",
			},
		},
		target: {
			page_title: "À qui cela s'adresse?",
			hero: {
				main_text: "Transformez votre manière d'apprendre l'Histoire",
				secondary_text:
					"Offrez un apprentissage historique engageant aux étudiants, enseignants et chercheurs",
				cta: "Débutez votre voyage",
			},
			overview: {
				big_title: "Libérez le pouvoir de l'apprentissage interactif",
				overline: "Une plateforme adaptée à vos besoins",
				argument_1: "Histoires<br/>intéressantes",
				argument_2: "Explorez<br/>l'histoire",
				argument_3: "Leçons<br/>captivantes",
				argument_4: "Découvertes<br/>amusantes",
			},
			student: {
				overline: "Pour les étudiants comme Léa",
				title: "Meilleures notes, apprentissage stimulant",
				description:
					"Transformez l'histoire de l'ennuyeuse à l'excitante!<br/>Apprenez avec des récits immersifs et explorez activement le passé. Avec WISER.LY, l'histoire devient une aventure que vous ne voudrez pas manquer!",
			},
			teacher: {
				overline: "Pour les enseignants comme Jean-Luc",
				title: "Leçons captivantes, préparées rapidement",
				description:
					"Gagnez du temps et simplifiez la préparation de vos leçons!<br/>Créez des leçons captivantes avec des visualisations automatiques, et laissez vos élèves explorer le contenu sur leurs appareils préférés : téléphones et tablettes.",
			},
			enthusiast: {
				overline: "Pour les passionnés comme Marie",
				title: "Communiquez facilement vos découvertes",
				description:
					"Découvrez les insights cachés de l'histoire et plongez-vous dans vos époques préférées.<br/>Comprenez facilement les événements clés, et partagez sans effort vos découvertes avec d'autres sur WISER.LY.",
			},
			why_it_is_for_you: {
				overline: "Adapté à vos besoins",
				title: "Parfait pour vous",
				description:
					"WISER.LY rend l'histoire passionnante pour les étudiants, simple pour les enseignants, et éclairante pour les chercheurs. Un outil essentiel pour l'apprentissage et la découverte.",
			},
			advertisement: {
				title: "Commencez dès aujourd'hui<br/>avec WISER.LY",
				cta: "Rejoignez la communauté",
			},
		},
		about: {
			page_title: "À propos de nous",
			hero: {
				main_text: "Notre voyage à travers l'Histoire",
				secondary_text: "Faire revivre le passé pour les générations futures",
			},
			title: {
				main_text:
					"Nous sommes des chercheurs enthousiastes et passionnés convaincus que le savoir et les découvertes doivent être partagés entre toutes les communautés afin que chacun puisse mieux comprendre son histoire et sa culture.",
				secondary_text:
					"<p>Connaître l'histoire et la culture est essentiel pour les nouvelles générations car cela leur permet de comprendre les racines de leur identité, les erreurs du passé, et les leçons qui en découlent. Cela nourrit leur esprit critique et leur sens de la citoyenneté, tout en les aidant à appréhender la diversité et la richesse du monde.</p> <p>En s'ancrant dans un passé collectif, ils peuvent mieux construire l'avenir, éviter les répétitions tragiques, et participer de manière éclairée et responsable à la société globale.</p><p>C'est une manière de développer une conscience plus profonde du monde et de leur place en son sein.</p>",
			},
			why: {
				title: "Pourquoi WISER.LY?",
				text_1:
					"Un élément clé de ce projet est de comprendre les événements significatifs de l'Histoire et d'expliquer les différentes perspectives des acteurs impliqués.",
				text_2:
					"En encourageant la diffusion des connaissances et en en facilitant l'accès, nous voulons contribuer à un avenir meilleur et plus éclairé pour tous. Cet engagement guide chacune de nos actions dans cette entreprise importante.",
				cta: "Découvrez notre travail",
			},
			vision: {
				text: "Notre vision est d'inspirer les gens à améliorer leurs connaissances afin qu'ils puissent faire de meilleurs choix pour notre avenir commun",
			},
		},
		contact: {
			page_title: "Contactez-nous",
			hero: {
				main_text: "Où nous trouver ?",
				discord_name: "Notre serveur communautaire",
				linkedin_name: "Notre page entreprise",
				twitter_name: "Notre fil",
			},
			form: {
				title: "N'hésitez pas à nous contacter.<br/>Nous serons ravis de vous entendre.",
				label: {
					name: "Nom",
					email: "E-mail",
					subject: "Sujet",
					message: "Entrez votre message ici.",
				},
				error: {
					name_required: "Le nom est requis",
					email_required: "L'e-mail est requis",
					email_valid: "L'e-mail doit être une adresse valide",
					subject_required: "Le sujet est requis, faites-nous savoir ce dont vous voulez parler",
					message_required:
						"Le message est requis, veuillez expliquer pourquoi vous nous contactez",
				},
				submit_button: "Envoyer",
				confirmation_message_1: "Merci pour votre message.",
				confirmation_message_2: "Nous vous répondrons très prochainement.",
			},
		},
		terms_and_conditions: {
			page_title: "Conditions Générales",
		},
		privacy_policy: {
			page_title: "Politique de confidentialité",
		},
	},
	// TABLEAU DE BORD
	dashboard: {
		nav: {
			history: {
				subheader: "Histoire",
				narratives: "Récits",
				interviews: "Interviews",
			},
			exploration: {
				subheader: "Exploration",
				elements: "Éléments",
				relations: "Relations",
				geo_map: "Carte géographique",
				timelines: "Chronologies",
			},
			workspace: {
				subheader: "Espace de travail",
				information: "Informations",
				members: "Membres",
			},
		},
		user: {
			page_title: "Utilisateur",
			breadcrumb: {
				heading: "Utilisateur",
				level_1: "Utilisateur",
			},
			profile: "Profil",
			profile_explanation: "Modifiez votre nom, votre biographie ou votre mot de passe",
			contacts: "Contacts",
			contacts_explanation: "Trouvez et ajoutez de nouveaux contacts, afin de pouvoir collaborer",
		},
		user_account: {
			page_title: "Profil",
			breadcrumb: {
				heading: "Profil",
				level_1: "Utilisateur",
				level_2: "Profil",
			},
			tabs: {
				general: "Général",
				change_password: "Changer le mot de passe",
			},
			profile_form: {
				label: {
					first_name: "Prénom",
					last_name: "Nom",
					display_name: "Nom affiché",
					avatar: "Avatar",
					country: "Pays",
					about: "À propos",
					dark_mode: "Mode sombre",
				},
				helper: {
					image_formats: "Formats autorisés *.jpeg, *.jpg, *.png, *.gif",
					image_size: "taille maximale de",
				},
				error: {
					first_name_required: "Prénom requis",
					first_name_pattern:
						"Le prénom doit commencer par une lettre et ne peut contenir aucun caractère spécial",
					last_name_required: "Nom requis",
					last_name_pattern:
						"Le nom doit commencer par une lettre et ne peut contenir aucun caractère spécial",
					display_name_required: "Nom d'affichage requis",
					avatar_required: "Avatar requis",
				},
				save_button: "Enregistrer les modifications",
				success_snackbar: "Mise à jour réussie!",
				failure_snackbar: "Échec de la mise à jour",
			},
			change_password_form: {
				label: {
					old_password: "Ancien mot de passe",
					new_password: "Nouveau mot de passe",
					confirm_password: "Confirmer le nouveau mot de passe",
				},
				helper: {
					password:
						"Le mot de passe doit comporter au minimum 8 caractères, 1 chiffre, 1 lettre, 1 caractère spécial",
				},
				error: {
					old_password_required: "Ancien mot de passe requis",
					new_password_required: "Nouveau mot de passe requis",
					confirm_password_required: "Confirmation du nouveau mot de passe requise",
					password_pattern:
						"Le mot de passe doit comporter au moins 8 caractères, contenir au moins un chiffre, une lettre et un caractère spécial",
					passwords_must_match: "Les mots de passe doivent correspondre",
					new_passwords_must_differ:
						"Le nouveau mot de passe doit être différent de l'ancien mot de passe",
					invalid_credentials: "L'ancien mot de passe est invalide",
				},
				save_button: "Enregistrer les modifications",
				success_snackbar: "Mise à jour réussie!",
				failure_snackbar: "Échec de la mise à jour",
			},
		},
		user_contacts: {
			page_title: "Contacts",
			breadcrumb: {
				heading: "Contacts",
				level_1: "Utilisateur",
				level_2: "Contacts",
			},
			new_contact_button: "Nouveau contact",
			friend_code: "Votre code est : ",
			helper_friend_code:
				"Communiquez ce code à vos amis pour qu'ils puissent vous ajouter en tant que contact",
			code_copied_to_clipboard: "Code copié dans le presse-papiers",
			table: {
				clear_filter: "Effacer",
				status: {
					all: "Tous",
					pending: "En attente",
					accepted: "Accepté",
					waiting: "En attente de réponse",
				},
				label: {
					name: "Nom",
					message: "Message",
					status: "Statut",
				},
				action: {
					validate: "Valider",
					ignore: "Ignorer",
					cancel: "Annuler",
					delete_confirm_title: "Supprimer la demande",
					delete_confirm_text: "Êtes-vous sûr de vouloir supprimer cette demande?",
					delete_button: "Supprimer",
				},
				accept_success_snackbar: "Demande de contact acceptée",
				delete_success_snackbar: "Demande de contact supprimée",
				failure_snackbar: "Erreur lors de la mise à jour de la demande de contact",
			},
			new_dialog_form: {
				title: "Envoyer une demande de contact",
				helper: "Votre ami devra accepter avant de devenir un contact",
				label: {
					friend_code_or_email: "Code ami ou e-mail",
					message: "Message",
				},
				error: {
					friend_code_or_email_required:
						"Vous devez fournir soit le code de votre ami, soit son e-mail",
					friend_code_or_email_pattern:
						"Le code doit être au format XXXX-XXXX ou l'e-mail doit être valide",
					email_valid: "L'e-mail doit être une adresse valide",
					invalid_friend: "Ce code ou cet e-mail n'existe pas",
					cant_be_own_friend: "Ce code ou cet e-mail vous appartient ;-)",
					contact_request_exists: "Vous avez déjà envoyé une demande de contact à cet utilisateur",
					already_friend: "Vous êtes déjà ami avec cet utilisateur",
				},
				new_button: "Envoyer",
				cancel_button: "Fermer",
				success_snackbar: "Demande de contact envoyée",
			},
		},
		workspace: {
			page_title: "Espace de travail",
			breadcrumb: {
				heading: "Espace de travail",
				level_1: "Tous les espaces de travail",
				level_2: "Espace de travail",
			},
			exploration: "Exploration",
			history: "Histoire",
			settings: "Paramètres",
			elements: "Éléments",
			elements_explanation: "Tous vos éléments",
			relations: "Relations",
			relations_explanation: "Les relations entre les éléments",
			geomap: "Carte géographique",
			geomap_explanation: "Vos éléments sur la carte du monde",
			timelines: "Chronologies",
			timelines_explanation: "Vos éléments dans la chronologie",
			narratives: "Récits",
			narratives_explanation: "Les perspectives des acteurs à travers des récits",
			information: "Informations",
			information_explanation:
				"Les informations de votre espace de travail ",
			members: "Membres",
			members_explanation:
				"Qui peut accéder à votre espace de travail",
		},
		workspace_list: {
			page_title: "Espaces de travail",
			breadcrumb: {
				heading: "Tous les espaces de travail",
				level_1: "Tous les espaces de travail",
			},
			new_workspace_button: "Nouvel espace de travail",
			ws_type: {
				all: "Tous",
				private: "Privé",
				public: "Public",
			},
			action: {
				information: "Informations",
				members: "Membres",
				cancel: "Annuler",
				delete: "Supprimer",
				delete_confirm_title: "Supprimer l'espace de travail",
				delete_confirm_text:
					"Êtes-vous sûr de vouloir supprimer cet espace de travail? toutes les données seront perdues!",
				delete_button: "Supprimer",
			},
			delete_success_snackbar: "Espace de travail supprimé",
			delete_failure_snackbar: "Erreur lors de la suppression de l'espace de travail",
		},
		workspace_create: {
			page_title: "Nouvel espace de travail",
			breadcrumb: {
				heading: "Espaces de travail",
				level_1: "Tous les espaces de travail",
				level_2: "Créer",
			},
			new_workspace_button: "Nouvel espace de travail",
			new_workspace_form: {
				label: {
					name: "Nom",
					language: "Langue",
					audience_age: "Age de l'audience",
					description: "Description",
					image: "Couverture",
				},
				error: {
					name_required: "Le nom est requis",
				},
				cancel_button: "Annuler",
				create_button: "Créer",
				success_snackbar: "Espace de travail créé!",
				failure_snackbar: "Erreur lors de la création de l'espace de travail",
			},
		},
		workspace_settings: {
			page_title: "Paramètres",
			breadcrumb: {
				heading: "Paramètres",
				level_1: "Tous les espaces de travail",
				level_2: "Espace de travail",
				level_3: "Paramètres",
			},
			information: "Informations",
			information_explanation:
				"Modifiez les informations de votre espace de travail afin que d'autres puissent comprendre de quoi il s'agit",
			members: "Membres",
			members_explanation:
				"Choisissez qui peut accéder à votre espace de travail pour collaborer ou simplement le lire",
		},
		workspace_information: {
			page_title: "Informations",
			breadcrumb: {
				heading: "Informations",
				level_1: "Tous les espaces de travail",
				level_2: "Espace de travail",
				level_3: "Paramètres",
				level_4: "Informations",
			},
			new_workspace_button: "Nouvel espace de travail",
			error_getting_data:
				"Récupération des informations de l'espace de travail à partir du serveur",
			edit_workspace_form: {
				label: {
					name: "Nom",
					description: "Description",
					image: "Couverture",
				},
				error: {
					name_required: "Le nom est requis",
				},
				cancel_button: "Annuler",
				create_button: "Modifier",
				success_snackbar: "Espace de travail modifié!",
				failure_snackbar: "Erreur lors de la modification de l'espace de travail",
			},
		},
		workspace_members: {
			page_title: "Membres de l'espace de travail",
			breadcrumb: {
				heading: "Membres",
				level_1: "Tous les espaces de travail",
				level_2: "Espace de travail",
				level_3: "Paramètres",
				level_4: "Membres",
			},
			new_contact_button: "Nouveau contact",
			table: {
				clear_filter: "Effacer",
				right: {
					all: "Tous",
					none: "Aucun",
				},
				label: {
					name: "Nom",
					friend_code: "Code ami",
					level: "Droit d'accès",
				},
				action: {
					giveAdmin: "Définir comme Administrateur",
					giveWrite: "Définir comme Contributeur",
					giveRead: "Définir comme Abonné",
					giveNone: "Retirer les droits",
				},
				success_snackbar: "Droit d'accès mis à jour",
				failure_snackbar: "Erreur lors de la mise à jour du droit d'accès",
			},
		},
		exploration: {
			page_title: "Exploration",
			breadcrumb: {
				heading: "Exploration",
				level_1: "Tous les espaces de travail",
				level_2: "Espace de travail",
				level_3: "Exploration",
			},
			exploration: "Exploration",
			elements: "Éléments",
			elements_explanation: "Tous vos éléments",
			relations: "Relations",
			relations_explanation: "Les relations entre les éléments",
			geomap: "Carte géographique",
			geomap_explanation: "Vos éléments sur la carte du monde",
			timelines: "Chronologies",
			timelines_explanation: "Vos éléments sur les chronologies",
		},
		exploration_elements: {
			page_title: "Éléments",
			breadcrumb: {
				heading: "Éléments",
				level_1: "Tous les espaces de travail",
				level_2: "Espace de travail",
				level_3: "Exploration",
				level_4: "Éléments",
			},
			new_element_button: "Nouvel élément",
			find_relations_dialog: {
				title: "Trouver de nouvelles relations",
				back_button: "Retour",

				cancel_button: "Annuler",
				create_relations_button: "Créer de nouvelles relations",
			},
			delete_confirm_dialog: {
				title: "Supprimer l'élément",
				text: "Êtes-vous sûr de vouloir supprimer cet élément? toutes les données seront perdues!",
				cancel_button: "Annuler",
				delete_button: "Supprimer",
				delete_success_snackbar: "Élément supprimé",
				delete_failure_snackbar: "Erreur lors de la suppression de l'élément",
			},
			action: {
				edit: "Éditer",
				find_relations: "Trouver des relations",
				relations: "Relations",
				geomap: "GeoMap",
				timelines: "Chronologies",
				delete: "Supprimer",
			},
			empty: {
				text_1: "Vous n'avez encore aucun élément",
				text_2: "Les éléments sont utilisés pour construire votre contexte.",
				text_3: "Créons votre premier élément.",
				button: "Créer un élément",
			},
			no_elements: "Il n'y a aucun élément à afficher",
		},
		exploration_relations: {
			page_title: "Relations",
			breadcrumb: {
				heading: "Relations",
				level_1: "Tous les espaces de travail",
				level_2: "Espace de travail",
				level_3: "Exploration",
				level_4: "Relations",
			},
			new_element_button: "Nouvel élément",
			full_screen_button: "Plein écran",
			save_positions_button: "Enregistrer les positions",
			restore_positions_button: "Restaurer les positions",
			change_element_size_button: "Changer la taille des éléments en fonction de leur période",
			graph_manipulation_helper: "Glissez-déposez pour déplacer, Maj pour multi-sélectionner, Suppr/Retour arrière pour supprimer",
			delete_dialog: {
				delete_node: {
					title: "Supprimer les éléments",
					content:
						"Êtes-vous sûr de vouloir supprimer {{nbNodes}} élément(s)? toutes les données contenues dans ces éléments seront perdues.",
				},
				delete_edge: {
					title: "Supprimer les relations",
					content: "Êtes-vous sûr de vouloir supprimer {{nbEdges}} relation(s)?",
				},
				delete_both: {
					title: "Supprimer les éléments et les relations",
					content:
						"Êtes-vous sûr de vouloir supprimer {{nbNodes}} élément(s) et {{nbEdges}} relation(s)? toutes les données contenues dans ces éléments seront perdues.",
				},
				delete_button: "Supprimer",
				cancel_button: "Annuler",
			},
		},
		exploration_geomap: {
			page_title: "Carte géographique",
			breadcrumb: {
				heading: "Carte géographique",
				level_1: "Tous les espaces de travail",
				level_2: "Espace de travail",
				level_3: "Exploration",
				level_4: "Carte géographique",
			},
			new_element_button: "Nouvel élément",
		},
		exploration_timelines: {
			page_title: "Chronologies",
			breadcrumb: {
				heading: "Chronologies",
				level_1: "Tous les espaces de travail",
				level_2: "Espace de travail",
				level_3: "Exploration",
				level_4: "Chronologies",
			},
			new_element_button: "Nouvel élément",
			no_elements: "Il n'y a aucun élément à afficher",
		},
		element_cve: {
			tabs: {
				general: "Général",
				geo: "Géo",
				medias: "Médias",
				relations: "Relations",
				sources: "Sources",
			},
			drawer: {
				denomination: "Dénomination",
				tags: "Étiquettes",
				dates: "Dates",
				geo: "Géo",
				medias: "Médias",
				description: "Description",
				sources: "Sources",
				searches: "Recherches",
			},
			tooltip: {
				expand: "Ouvrir en plein écran",
				find_relations: "Trouver des relations",
				cards: "Voir en liste de cartes",
				relations: "Voir sur le graphe des relations",
				geomap: "Voir sur la carte géographique",
				timelines: "Voir sur les chronologies",
				close_button: "Fermer",
			},
			element_form: {
				title_create_form: "Créer un nouvel élément",
				title_geojson_form: "Editeur GeoJSON",
				label: {
					name: "Nom",
					other_names: "Autres noms",
					description: "Description",
					image: "Couverture",
					tags: "Étiquettes",
					dates: "Dates",
					start_date: "Date de début",
					end_date: "Date de fin",
					period: "Période",
					geo: "Géo",
					location: "Emplacement",
					no_locations: "Aucun emplacement",
					coordinates: "Coordonnées",
					relations_table: {
						head_column: "O/N",
						element_name: "Élément",
						link: "Lien",
						dates: "Dates",
						tags: "Étiquettes",
						source: "source",
						target: "cible",
					},
					additional_source: "Source supplémentaire",
					geojson: "GeoJSON",
				},
				helper: {
					link_edit_title: "Modifier le lien",
					link_edit_text: "Quel est le lien entre ces éléments?",
					no_sources_text: "Vous n'avez encore aucune source",
					add_source: "Cliquez sur '+' pour ajouter la nouvelle source",
					coordinates_format: "Lat,Long → xxx.xxxx, xxx.xxxx",
					tag_create: "Appuyez sur Entrée pour valider votre étiquette",
					other_name_create: "Appuyez sur Entrée pour valider votre autre nom",
					map_data_color: "Choisissez une couleur pour cet élément sur la Carte Géographique",
				},
				error: {
					name_required: "Le nom est requis",
					date_format: "Date ou format invalide (JJ/MM/AAAA)",
					date_limit: "La date doit être comprise entre 4500 avant JC et 9999 après JC",
					date_chronology: "La date de fin doit être postérieure à la date de début",
					coordinates_format:
						"Les coordonnées doivent respecter le format suivant: Lat,Long → xxx.xxxx, xxx.xxxx",
					coordinates_limits:
						"La latitude doit être comprise entre -90 & +90, la longitude doit être comprise entre -180 & +180",
						invalid_geojson: "Ce GeoJSON n'est pas valide",
				},
				no_result: {
					no_search_results: "Aucun résultat de recherche sur wiki",
					no_relations_results: "Aucune relation",
				},
				cancel_button: "Annuler",
				create_button: "Créer",
				create_and_open_button: "Créer et ouvrir",
				search_wiki_button: "Rechercher sur Wiki",
				edit_button: "Enregistrer les modifications",
				back_button: "Retour",
				open_geojson_button: "Ouvrir l'éditeur GeoJSON",
				create_success_snackbar: "Élément créé!",
				create_failure_snackbar: "Erreur lors de la création de l'élément",
				edit_success_snackbar: "Élément modifié!",
				edit_failure_snackbar: "Erreur lors de la modification de l'élément",
				edit_link_button: "Confirmer",
				info_button: "Info",
				geocode_button: "Géocodez votre emplacement en coordonnées",
			},
		},
		element_edit: {
			page_title: "Modifier l'élément : ",
			breadcrumb: {
				heading: "Modifier l'élément",
				level_1: "Tous les espaces de travail",
				level_2: "Espace de travail",
				level_3: "Exploration",
				level_4: "Modifier l'élément",
			},
			new_element_button: "Nouvel élément",
		},
		element_view: {
			page_title: "Voir l'élément : ",
			breadcrumb: {
				heading: "Voir l'élément",
				level_1: "Tous les espaces de travail",
				level_2: "Espace de travail",
				level_3: "Exploration",
				level_4: "Voir l'élément",
			},
			edit_element_button: "Modifier l'élément",
		},
		history: {
			page_title: "Histoire",
			breadcrumb: {
				heading: "Histoire",
				level_1: "Tous les espaces de travail",
				level_2: "Espace de travail",
				level_3: "Histoire",
			},
			history: "Histoire",
			narratives: "Récits",
			narratives_explanation: "Découvrez les perspectives des acteurs à travers des récits",
			interviews: "Interviews",
			interviews_explanation: "Interviewez des personnages historiques",
		},
		history_narratives: {
			page_title: "Récits",
			breadcrumb: {
				heading: "Récits",
				level_1: "Tous les espaces de travail",
				level_2: "Espace de travail",
				level_3: "Histoire",
				level_4: "Récits",
			},
			new_narrative_button: "Nouveau récit",
			empty: {
				text_1: "Vous n'avez encore aucun récit",
				text_2:
					"Les récits sont utilisés pour présenter l'histoire d'un acteur dans votre contexte.",
				text_3: "Créons votre premier récit.",
				button: "Créer un récit",
			},
			no_narratives: "Il n'y a aucun récit à afficher",
			action: {
				edit: "Éditer",
				cancel: "Annuler",
				delete: "Supprimer",
				delete_confirm_title: "Supprimer le récit",
				delete_confirm_text:
					"Êtes-vous sûr de vouloir supprimer ce récit? toutes les données seront perdues!",
				delete_button: "Supprimer",
			},
			delete_success_snackbar: "Récit supprimé",
			delete_failure_snackbar: "Erreur lors de la suppression du récit",
		},
		history_interviews: {
			page_title: "Interviews de personnages historiques",
			breadcrumb: {
				heading: "Interviews de personnages historiques",
				level_1: "Tous les espaces de travail",
				level_2: "Espace de travail",
				level_3: "Histoire",
				level_4: "Interviews",
			},
			new_character_button: "Nouveau personnage",
			empty: {
				text_1: "Vous n'avez encore aucun personnage à interviewer",
				text_2:
					"Avec les interviews, vous pouvez discuter avec des personnages historiques.",
				text_3: "Choisissons votre premier personnage.",
				button: "Créer un personnage",
			},
			no_interviews: "Il n'y a aucune interview à afficher",
			action: {
				cancel: "Annuler",
				delete: "Supprimer",
				delete_confirm_title: "Supprimer le personnage",
				delete_confirm_text:
					"Êtes-vous sûr de vouloir supprimer ce personnage? toutes les utilisateurs perdront leur interview!",
				delete_button: "Supprimer",
			},
			delete_success_snackbar: "Personnage supprimé",
			delete_failure_snackbar: "Erreur lors de la suppression du personnage",
		},
		narrative_create: {
			page_title: "Créer un récit",
			breadcrumb: {
				heading: "Créer un récit",
				level_1: "Tous les espaces de travail",
				level_2: "Espace de travail",
				level_3: "Histoire",
				level_4: "Créer un récit",
			},
			new_narrative_form: {
				label: {
					intro: "Introduction",
					name: "Nom",
					introText: "Présentation",
					image: "Image",
					chapters: "Chapitres",
					chapter_title: "Titre",
					chapter_image: "Image",
					chapter_text: "L'histoire",
					chapter_elements: "Les éléments associés",
					element_checked: "O/N",
					element_name: "Nom",
					element_dates: "Dates",
					element_tags: "Étiquettes",
				},
				error: {
					name_required: "Le nom est requis",
					chapter_title_required: "Le titre est requis",
					chapter_text_required: "L'histoire est requise",
				},
				helper: {
					form_helper: "Montrez la perspective à travers un récit engageant",
					name: "Titre pour le récit",
					intro: "Expliquez brièvement de quoi parle le récit",
					chapters: "Construisez l'histoire en chapitres avec des éléments clés",
					add_chapter: "Commencez par créer le premier chapitre",
					chapter_title: "Comment s'appelle ce chapitre?",
					chapter_text: "Expliquez l'histoire que vous voulez raconter dans ce chapitre",
				},
				action: {
					edit_chapter: "Éditer",
					delete_chapter: "Supprimer",
					delete_confirm_title: "Supprimer le chapitre",
					delete_confirm_text: "Êtes-vous sûr de vouloir supprimer ce chapitre?",
					delete_button: "Supprimer",
					cancel_button: "Annuler",
				},
				new_chapter_button: "Nouveau chapitre",
				cancel_button: "Annuler",
				create_button: "Créer",
				edit_button: "Éditer",
				create_success_snackbar: "Récit créé!",
				create_failure_snackbar: "Erreur lors de la création du récit",
				edit_success_snackbar: "Récit modifié!",
				edit_failure_snackbar: "Erreur lors de la modification du récit",
				chapter_form: {
					new_title: "Nouveau chapitre",
					edit_title: "Éditer le chapitre",
					cancel_button: "Annuler",
					add_chapter_button: "Ajouter un chapitre",
					edit_chapter_button: "Éditer le chapitre",
				},
			},
		},
		narrative_edit: {
			page_title: "Modifier le récit",
			breadcrumb: {
				heading: "Modifier le récit",
				level_1: "Tous les espaces de travail",
				level_2: "Espace de travail",
				level_3: "Histoire",
				level_4: "Modifier le récit",
			},
			new_narrative_button: "Nouveau récit",
		},
		narrative_view: {
			page_title: "Voir le récit",
			breadcrumb: {
				heading: "Voir le récit",
				level_1: "Tous les espaces de travail",
				level_2: "Espace de travail",
				level_3: "Histoire",
				level_4: "Voir le récit",
			},
			edit_narrative_button: "Modifier le récit",
		},
		interview_create: {
			page_title: "Créer une interview",
			breadcrumb: {
				heading: "Créer une interview",
				level_1: "Tous les espaces de travail",
				level_2: "Espace de travail",
				level_3: "Histoire",
				level_4: "Créer une interview",
			},
			new_interview_form: {
				label: {
					intro: "Introduction",
					name: "Nom",
					tone: "Ton",
					image: "Image",
				},
				error: {
					name_required: "Le nom est requis",
				},
				helper: {
					form_helper: "Montrez la perspective à travers un récit engageant",
					name: "Qui est le personnage historique ? (soyez précis)",
					tone: "Quel est le ton que doit adopter le personnage ?",
				},
				action: {
					edit_chapter: "Éditer",
					delete_chapter: "Supprimer",
					delete_confirm_title: "Supprimer le chapitre",
					delete_confirm_text: "Êtes-vous sûr de vouloir supprimer ce chapitre?",
					delete_button: "Supprimer",
					cancel_button: "Annuler",
				},
				cancel_button: "Annuler",
				create_button: "Créer",
				create_success_snackbar: "Interview créé!",
				create_failure_snackbar: "Erreur lors de la création de l'interview",
			},
		},
		interview_view: {
			page_title: "Voir l'interview",
			breadcrumb: {
				heading: "Voir l'interview",
				level_1: "Tous les espaces de travail",
				level_2: "Espace de travail",
				level_3: "Histoire",
				level_4: "Voir l'interview",
			},
			label:{
				input_chat: "Votre Message",
				input_chat_loading: "Récupération de la réponse..."
			},
			action: {
				send: "Envoyer"
			},			
			send_message_failure_snackbar: "Erreur lors de l'envoi de message",
		},
	},
	// COMMUN
	common: {
		basic: {
			element: "élément",
			element_plural: "éléments",
			relation: "relation",
			relation_plural: "relations",
			narrative: "récit",
			narrative_plural: "récits",
			member: "membre",
			member_plural: "membres",
		},
		workspace_right: {
			personal: "Personnel",
			owner: "Propriétaire",
			admin: "Administrateur",
			write: "Contributeur",
			read: "Abonné",
			public: "Public",
		},
		sort: {
			sort_by: "Trier par:",
			lastUpdated: "Dernière mise à jour",
      name: "Nom",
      latest: "Plus récent",
      oldest: "Plus ancien",
			nameAsc: "Nom: A-Z",
			nameDesc: "Nom: Z-A",
			dateAsc: "Date: Ancien-Nouveau",
			dateDesc: "Date: Nouveau-Ancien",
		},
		filter: {
			search: "Rechercher...",
			filter_by_name: "Filtrer par nom...",
			filters: "Filtres",
			clear: "Effacer le filtre",
			all: "Tous",
			tags: "Étiquettes",
			period: "Période",
			related: "Lié",
			unrelated: "Non lié",
			min: "min",
			max: "max",
			year: "Année",
		},
	},
};

export default fr;
