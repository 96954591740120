// ADMIN
export const API_ADMIN_LOGIN: string = "/admin/login";
export const API_ADMIN_LOGOUT: string = "/admin/logout";
// LOGIN
export const API_LOGIN: string = "/login";
export const API_LOGOUT: string = "/logout";
// USERS
export const API_USERS: string = "/users";
export const API_USERS_COUNT: string = "/users/count";
export const API_USERS_ASK_RESET_PASSWORD: string = "/users/reset-password";
export const API_USERS_RESET_PASSWORD: string = "/users/update-password";
export const API_USERS_SIGNUP: string = "/users/signup";
// USERS with id
export const API_USERS_CONFIRM_EMAIL: string = "/users/:id/confirm-email";
export const API_USERS_GET_ME: string = "/users/me";
export const API_USERS_UPDATE_ME: string = "/users/me/update-info";
export const API_USERS_UPDATE_WORKSPACE: string = "/users/me/update-workspace";
export const API_USERS_UPDATE_EMAIL: string = "/users/me/update-email";
export const API_USERS_UPDATE_PASSWORD: string = "/users/me/update-password";
// ANALYTICS 
export const API_ANALYTICS_TRACK: string = "/analytics/track";
// EVENTS 
export const API_EVENTS: string = "/events";
export const API_EVENTS_PUBLIC_VISIT_COUNT: string = "/events/publicVisitCount";
export const API_EVENTS_DASHBOARD_VISIT_COUNT: string = "/events/dashboardVisitCount";
// INQUIRIES
export const API_INQUIRIES: string = "/inquiries";
// WORKSPACES
export const API_WORKSPACES: string = "/workspaces";
export const API_WORKSPACES_COUNT: string = "/workspaces/count";
export const API_WORKSPACES_COUNT_ACCESS_RIGHTS: string = "/workspaces/countAccessRights";
export const API_WORKSPACES_SEARCH: string = "/workspaces/search";
export const API_WORKSPACES_ACCESS_RIGHTS: string = "/workspaces/:id/access-rights";
export const API_WORKSPACES_SHARE: string = "/workspaces/:id/share";
export const API_WORKSPACES_UNSHARE: string = "/workspaces/:id/unshare";
// CONTACTS
export const API_CONTACTS: string = "/contacts";
export const API_CONTACTS_COUNT: string = "/contacts/count";
export const API_CONTACTS_VALIDATE: string = "/contacts/:id/validate";
export const API_CONTACTS_IGNORE: string = "/contacts/:id/ignore";
export const API_CONTACTS_CANCEL: string = "/contacts/:id/cancel";
// NARRATIVES
export const API_NARRATIVES: string = "/narratives";
export const API_NARRATIVES_COUNT: string = "/narratives/count";
// ELEMENTS
export const API_ELEMENTS: string = "/elements";
export const API_ELEMENTS_COUNT: string = "/elements/count";
export const API_POSITIONS: string = "/elements/positions";
// TAGS
export const API_TAGS: string = "/tags";
// RELATIONS
export const API_RELATIONS: string = "/relations";
export const API_RELATIONS_COUNT: string = "/relations/count";
// TASKS
export const API_TASKS: string = "/tasks";
// WIKI
export const API_WIKI: string = "/wiki";
// INTERVIEWS
export const API_CHARACTERS: string = "/characters";
export const API_CHARACTERS_COUNT: string = "/characters/count";
export const API_INTERVIEWS: string = "/interviews";
export const API_INTERVIEWS_COUNT: string = "/interviews/count";



