import { Link as RouterLink, useLocation } from 'react-router-dom';
// @mui
import { Box, Grid, Link, Stack, Divider, Container, Typography, IconButton } from '@mui/material';
// routes
import { PATH_PAGE, PATH_SOCIALS } from 'src/routes/paths';
// components
import Logo from 'src/components/logo';
import { IconDiscord, IconLinkedin, IconTwitter } from 'src/icones/icones';
import { useLocales } from 'src/locales';

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: 'WISER.LY',
    children: [
      { name: 'About us', href: PATH_PAGE.about },
      { name: 'Contact us', href: PATH_PAGE.contact },
      // { name: 'FAQs', href: PATH_PAGE.faqs },
    ],
  },
  {
    headline: 'Legal',
    children: [
      { name: 'Terms and Conditions', href: PATH_PAGE.termsAndConditions },
      { name: 'Privacy Policy', href: PATH_PAGE.privacyPolicy },
    ],
  },
  {
    headline: 'Contact',
    children: [
      { name: 'support@wiser.ly', href: '#' },
      // { name: 'Mamer, Luxembourg', href: '#' },
    ],
  },
];



// ----------------------------------------------------------------------

export default function Footer() {
  const { pathname } = useLocation();
	// locales
	const { translate } = useLocales();

  const isHome = pathname === '/';

  const simpleFooter = (
    <Box
      component="footer"
      sx={{
        py: 5,
        textAlign: 'center',
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Container>
        <Logo sx={{ mb: 1, mx: 'auto' }} />

        <Typography variant="caption" component="div">
        {`${translate("public_page.footer.all_rights_reserved")}`}
          <br /> {`${translate("public_page.footer.made_by")}`} &nbsp;
          <Link href="https://wiser.ly/">WISER.LY</Link>
        </Typography>
      </Container>
    </Box>
  );

  const mainFooter = (
    <Box
      component="footer"
      sx={{
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Divider />

      <Container sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{
            xs: 'center',
            md: 'space-between',
          }}
          sx={{
            textAlign: {
              xs: 'center',
              md: 'left',
            },
          }}
        >
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
          </Grid>

          <Grid item xs={8} md={3}>
            <Typography variant="body2" sx={{ pr: { md: 5 } }}>
            {`${translate("public_page.footer.hero_text")}`}
            </Typography>

            <Stack
              spacing={1}
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{
                mt: 5,
                mb: { xs: 5, md: 0 },
              }}
            >
                <IconButton key="Discord" href={PATH_SOCIALS.discord}>
                  <IconDiscord />
                </IconButton>
                <IconButton key="LinkedIn" href={PATH_SOCIALS.linkedin}>
                  <IconLinkedin />
                </IconButton>
                <IconButton key="Twitter" href={PATH_SOCIALS.twitter}>
                  <IconTwitter />
                </IconButton>

            </Stack>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack
              spacing={5}
              justifyContent="space-between"
              direction={{ xs: 'column', md: 'row' }}
            >
              {LINKS.map((list) => (
                <Stack
                  key={list.headline}
                  spacing={2}
                  alignItems={{ xs: 'center', md: 'flex-start' }}
                >
                  <Typography component="div" variant="overline">
                    {list.headline}
                  </Typography>

                  {list.children.map((link) => (
                    <Link
                      key={link.name}
                      component={RouterLink}
                      to={link.href}
                      color="inherit"
                      variant="body2"
                    >
                      {link.name}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          variant="caption"
          component="div"
          sx={{
            mt: 10,
            pb: 5,
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          {`${translate("public_page.footer.all_rights_reserved_2023")}`}
        </Typography>
      </Container>
    </Box>
  );

  return isHome ? simpleFooter : mainFooter;
}
