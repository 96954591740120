// REMINDER: GEOJSON coordinates = [longitude, latitude]
// Lat: -90/+90
// Lng: -180/+180

import * as turf from "@turf/turf";
import { Feature, FeatureCollection } from "geojson";
import { IElementShort } from "src/@types/element";

import uuidv4 from "src/utils/uuidv4";

// -------------------------------- UTILS ---------------------------------------------------

export const getFeaturesFromElements = (data: IElementShort[]): FeatureCollection => {
	let features: Feature[] = [];
	data.forEach((element, index) => {
		if (!!element.mapData) {
			// must include the ID of the element in the properties
			element.mapData.features.forEach((feat) => {
				const properties = { ...feat.properties, elementId: element.id, elementIndex: index };
				features.push({ ...feat, properties: properties });
			});
		}
	});
	const coll = turf.featureCollection(features) as FeatureCollection;
	return coll;
};

export const getCenterAndZoom = (
	featureCollection: FeatureCollection
): { latitude: number; longitude: number; zoom: number } => {
	if (featureCollection.features.length === 0) return { latitude: 49, longitude: 6, zoom: 2 };
	const centerFeature = turf.centerOfMass(featureCollection);
	const coord = centerFeature.geometry.coordinates;
	return { latitude: coord[1], longitude: coord[0], zoom: 2 };
};

export const isValidGeoJSONFeature = (jsonString: string): boolean => {
	try {
		const parsedObject = JSON.parse(jsonString);
		if (
			parsedObject instanceof Object &&
			parsedObject.type === "Feature" &&
			parsedObject.geometry instanceof Object &&
			parsedObject.properties instanceof Object
		) {
			return true;
		}
		return false;
	} catch (error) {
		// Gestion des erreurs d'analyse JSON
		return false;
	}
};

export const mergeFeatures = (features1: Feature[], features2: Feature[]): FeatureCollection => {
	const mergedFeatures = features1.concat(features2);
	const uniqueFeatures = mergedFeatures.filter((feature, index, self) => {
		const featureGeometry = JSON.stringify(feature.geometry);
		return index === self.findIndex((f) => JSON.stringify(f.geometry) === featureGeometry);
	});
	return turf.featureCollection(uniqueFeatures) as FeatureCollection;
};

export const addFeature = (featureCollection: FeatureCollection, newFeature: Feature): FeatureCollection => {
  const updatedFeatures = [...featureCollection.features, newFeature];
  return {
    ...featureCollection,
    features: updatedFeatures,
  };
};

export const removeFeature = (featureCollection: FeatureCollection, feature: Feature): FeatureCollection => {
	const updatedFeatures = featureCollection.features.filter(
		(featureInArray) => JSON.stringify(featureInArray.geometry) !== JSON.stringify(feature.geometry)
	);
	return {
		...featureCollection,
		features: updatedFeatures,
	};
}

export const checkIdsInFeatures = (featureCollection: FeatureCollection): FeatureCollection => {
  const updatedFeatures = featureCollection.features.map((feature: Feature) => {
    if (!feature.id) feature.id = uuidv4();    
    return feature;
  });
  return {
    ...featureCollection,
    features: updatedFeatures,
  };
}


// -------------- GEO DATA FORMAT ------------------------

/**
 * lat: xx.xxxxxx
 * lng: xxx.xxxxxx
 * Point: lat, lng
 * Circle: lat, lng, radius in km
 * Line: lat, lng / lat, lng / ...
 * Polygon: lat, lng / lat, lng / ...
 */

const CIRCLE_STEPS = 64;

// ----------------- PARSING GEODATA TO GEOJSON --------------------------------------

export const parseCoordinates = (coordinates: string): [number, number] | null => {
	const [lat, lng] = coordinates.split(",").map(parseFloat);
	if (isNaN(lat) || isNaN(lng) || lat < -90 || lat > 90 || lng < -180 || lng > 180) {
		// console.error(`Invalid coordinates: ${coordinates}`);
		return null;
	}
	return [lng, lat];
};

export const getPointFromCoordinates = (coordinates: [number, number]) => {
	let point = turf.point(coordinates);
	return point;
};

export const getLineStringFromCoordinatesArray = (coordinatesArray: [number, number][]) => {
	let line = turf.lineString(coordinatesArray);
	let length = turf.length(line, { units: "kilometers" });
	line.properties = { "length (km)": length };
	return line;
};

export const getCirclePolygonFromCoordinatesAndRadius = (
	coordinatesCenter: [number, number],
	radiusInKm: number
) => {
	let options = { steps: CIRCLE_STEPS, units: "kilometers" as turf.Units };
	let circle = turf.circle(coordinatesCenter, radiusInKm, options);
	let area = turf.area(circle);
	area = turf.convertArea(area, "meters", "kilometers");
	circle.properties = { "area (km²)": area };
	return circle;
};

export const getPolygonFromCoordinatesArray = (coordinatesArray: [number, number][]) => {
	let polygon = turf.polygon([coordinatesArray]);
	let area = turf.area(polygon);
	area = turf.convertArea(area, "meters", "kilometers");
	polygon.properties = { "area (km²)": area };
	return polygon;
};

export const parseSimplePoint = (point: string): turf.Feature | null => {
	const [coordinates] = point.match(/(.+)/) || [];
	if (!coordinates) {
		// console.error(`Invalid format: ${line}`);
		return null;
	}
	const parsedCoordinates = parseCoordinates(coordinates);
	if (!parsedCoordinates) return null;
	return getPointFromCoordinates(parsedCoordinates);
};

export const parsePoint = (point: string): turf.Feature | null => {
	const [, coordinates] = point.match(/point:(.+)/) || [];
	if (!coordinates) {
		// console.error(`Invalid Point format: ${line}`);
		return null;
	}
	const parsedCoordinates = parseCoordinates(coordinates);
	if (!parsedCoordinates) return null;
	return getPointFromCoordinates(parsedCoordinates);
};

export const parseCircle = (circle: string): turf.Feature | null => {
	const [, centerCoordinates, radius] = circle.match(/circle:(.+), (.+)/) || [];
	if (!centerCoordinates || !radius) {
		// console.error(`Invalid Circle format: ${line}`);
		return null;
	}
	const parsedCenterCoordinates = parseCoordinates(centerCoordinates);
	if (!parsedCenterCoordinates) return null;

	const parsedRadius = parseFloat(radius);
	if (isNaN(parsedRadius)) {
		// console.error(`Invalid radius: ${radius}`);
		return null;
	}
	return getCirclePolygonFromCoordinatesAndRadius(parsedCenterCoordinates, parsedRadius);
};

export const parseLineString = (line: string): turf.Feature | null => {
	const [, coordinatesString] = line.match(/line:(.+)/) || [];
	if (!coordinatesString) {
		// console.error(`Invalid LineString format: ${line}`);
		return null;
	}
	const coordinatesArray: [number, number][] = [];
	coordinatesString.split(" / ").forEach((coords) => {
		const parsed = parseCoordinates(coords.trim());
		if (parsed) coordinatesArray.push(parsed);
		else {
			// console.error(`Invalid LineString format: ${line}`);
			return null;
		}
	});
	return getLineStringFromCoordinatesArray(coordinatesArray);
};

export const parsePolygon = (line: string): turf.Feature | null => {
	const [, polygonCoordinates] = line.match(/polygon:(.+)/) || [];
	if (!polygonCoordinates) {
		// console.error(`Invalid Polygon format: ${line}`);
		return null;
	}
	const coordinatesArray: [number, number][] = [];
	polygonCoordinates.split(" / ").forEach((coords) => {
		const parsed = parseCoordinates(coords);
		if (parsed) coordinatesArray.push(parsed);
		else {
			// console.error(`Invalid Polygon format: ${line}`);
			return null;
		}
	});
	return getPolygonFromCoordinatesArray(coordinatesArray);
};

export const parseGeoJSON = (input: string): turf.Feature[] | null => {
	const lines = input.replace(" ", "").toLocaleLowerCase().split("\n");
	const features: turf.Feature[] = [];
	for (const line of lines) {
		if (line.startsWith("point:")) {
			const feature = parsePoint(line);
			if (feature) features.push(feature);
			else return null;
		} else if (line.startsWith("circle:")) {
			const feature = parseCircle(line);
			if (feature) features.push(feature);
			else return null;
		} else if (line.startsWith("line:")) {
			const feature = parseLineString(line);
			if (feature) features.push(feature);
			else return null;
		} else if (line.startsWith("polygon:")) {
			const feature = parsePolygon(line);
			if (feature) features.push(feature);
			else return null;
		} else if (line.match(/^(-?\d+(\.\d+)?),\s?(-?\d+(\.\d+)?)$/)) {
			const feature = parseSimplePoint(line);
			if (feature) features.push(feature);
			else return null;
		} else if (line.trim() !== "") {
			// console.error(`Invalid line: ${line}`);
			return null;
		}
	}
	return features;
};

export const convertGeoDataToGeoJson = (input: string): string | null => {
	if (!input) return null;
	const geoFeatures = parseGeoJSON(input);
	if (geoFeatures === null) return null;
	let collection = turf.featureCollection(geoFeatures);
	return JSON.stringify(collection);
};

// -------------------------------- PARSING GEOJSON TO GEODATA ---------------------------------------------------

export const convertGeoJsonToGeoData = (geoJson: string): string | null => {
	try {
		const parsedGeoJson = JSON.parse(geoJson);
		if (
			!parsedGeoJson ||
			parsedGeoJson.type !== "FeatureCollection" ||
			!Array.isArray(parsedGeoJson.features)
		) {
			// console.error("Invalid GeoJSON format.");
			return null;
		}
		const geoDataArray: string[] = [];
		for (const feature of parsedGeoJson.features) {
			if (feature.type === "Feature" && feature.geometry) {
				const { type, coordinates } = feature.geometry;
				// console.log("type = " + type);
				// console.log("coordinates = " + coordinates);
				let geoData: string;
				switch (type) {
					case "Point":
						geoData = `Point: ${coordinates[1].toFixed(6)}, ${coordinates[0].toFixed(6)}`;
						break;
					case "Polygon":
						const circle = findCircleFromPolygon(coordinates[0]);
						if (circle) geoData = circle;
						else geoData = `Polygon: ${coordinatesArrayToString(coordinates[0])}`;
						break;
					case "LineString":
						geoData = `Line: ${coordinatesArrayToString(coordinates)}`;
						break;
					default:
						// console.error(`Unsupported geometry type: ${type}`);
						return null;
				}
				geoDataArray.push(geoData);
			}
		}
		return geoDataArray.join("\n");
	} catch (error) {
		// console.error("Invalid GeoJSON format: " + error);
		return null;
	}
};

const coordinatesArrayToString = (coordinates: [number, number][]): string => {
	let str = "";
	coordinates.forEach((coord: [number, number], index) => {
		str += `${coord[1].toFixed(6)}, ${coord[0].toFixed(6)}`;
		if (index !== coordinates.length - 1) str += " / ";
	});
	return str;
};

const findFarthestCoordinate = (coordinates: [number, number][]): [number, number] | null => {
	if (coordinates.length < 2) return null;
	const startPoint = turf.point(coordinates[0]);
	let farthestCoordinate = coordinates[0];
	let maxDistance = 0;
	for (let i = 1; i < coordinates.length; i++) {
		const currentPoint = turf.point(coordinates[i]);
		const currentDistance = turf.distance(startPoint, currentPoint);
		if (currentDistance > maxDistance) {
			maxDistance = currentDistance;
			farthestCoordinate = coordinates[i];
		}
	}
	return farthestCoordinate;
};

const findCircleFromPolygon = (coordinates: [number, number][]): string | null => {
	const startPoint = turf.point(coordinates[0]);
	const furtherPos = findFarthestCoordinate(coordinates);
	if (furtherPos === null) return null;
	const furtherPoint = turf.point(furtherPos);
	let midpoint = turf.midpoint(startPoint, furtherPoint);
	let radius = turf.distance(midpoint, startPoint, { units: "kilometers" });
	// Check if all points are equidistant from the midpoint within a tolerance
	const tolerance = 1e-5;
	for (const coord of coordinates) {
		const distance = turf.distance(midpoint, coord, { units: "kilometers" });
		if (Math.abs(distance - radius) > tolerance) {
			return null;
		}
	}
	// Return the center coordinates and radius of the circle
	return `Circle: ${midpoint.geometry.coordinates[1].toFixed(
		6
	)}, ${midpoint.geometry.coordinates[0].toFixed(6)}, ${radius.toFixed(6)}`;
};

// -------------------------------- CHECKING EXPRESSIONS ---------------------------------------------------

// everything without spaces, lowercasing, and line by line

const checkCoordinatesExp = (value: string): boolean => {
	const regex = new RegExp(
		/^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/
	);
	return regex.test(value);
};

const checkPointExp = (value: string): boolean => {
	const regex = new RegExp(
		/^point:[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/
	);
	return regex.test(value);
};

const checkCircleExp = (value: string): boolean => {
	const regex = new RegExp(
		/^circle:([-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)),([-+]?\d+(\.\d+)?)$/
	);
	return regex.test(value);
};

const checkLineExp = (value: string): boolean => {
	const regex = new RegExp(
		/^line:(?:[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)(?:\/|$))+$/
	);
	return regex.test(value);
};

const checkPolygonExp = (value: string): boolean => {
	const regex = new RegExp(
		/^polygon:(?:[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)(?:\/|$))+$/
	);
	return regex.test(value);
};

export const areCoordinatesValid = (input: string): boolean => {
	const lines = input.replace(" ", "").toLocaleLowerCase().split("\n");
	lines.forEach((line) => {
		if (
			!checkCoordinatesExp(line) &&
			!checkPointExp(line) &&
			!checkCircleExp(line) &&
			!checkLineExp(line) &&
			!checkPolygonExp(line)
		)
			return false;
	});
	return true;
};
