// routes
import { PATH_DASHBOARD } from "src/routes/paths";
// icons
import {
	// IconCards,
	// IconList,
	IconGeoMap,
	IconRelations,
	IconTimelines,
	IconNarratives,
	IconWSMembers,
	IconInterviews,
	IconWSInfo,
	IconElement,
} from "src/icones/icones";

// ----------------------------------------------------------------------

const navConfig = [
	// HISTORY
	// ----------------------------------------------------------------------
	{
		subheader: "dashboard.nav.history.subheader",
		items: [
			{
				title: "dashboard.nav.history.narratives",
				path: PATH_DASHBOARD.history.narratives(0),
				icon: <IconNarratives />,
			},
			{
				title: "dashboard.nav.history.interviews",
				path: PATH_DASHBOARD.history.interviews(0),
				icon: <IconInterviews />,
			},
		],
	},

	// EXPLORATION
	// ----------------------------------------------------------------------
	{
		subheader: "dashboard.nav.exploration.subheader",
		items: [
			{
				title: "dashboard.nav.exploration.elements",
				path: PATH_DASHBOARD.exploration.elements(0),
				icon: <IconElement />,
			},
			{
				title: "dashboard.nav.exploration.relations",
				path: PATH_DASHBOARD.exploration.relations(0),
				icon: <IconRelations />,
			},
			{
				title: "dashboard.nav.exploration.geo_map",
				path: PATH_DASHBOARD.exploration.geomap(0),
				icon: <IconGeoMap />,
			},
			{
				title: "dashboard.nav.exploration.timelines",
				path: PATH_DASHBOARD.exploration.timelines(0),
				icon: <IconTimelines />,
			},
		],
	},

	// WORKSPACE
	// ----------------------------------------------------------------------
	{
		subheader: "dashboard.nav.workspace.subheader",
		items: [
			{
				title: "dashboard.nav.workspace.information",
				path: PATH_DASHBOARD.workspace.information(0),
				icon: <IconWSInfo />,
			},
			{
				title: "dashboard.nav.workspace.members",
				path: PATH_DASHBOARD.workspace.members(0),
				icon: <IconWSMembers />,
			},
		],
	},
];

export default navConfig;
