import { axiosInstance as axios } from "./axiosInstance";
// paths
import { API_NARRATIVES, API_NARRATIVES_COUNT } from "./apiPaths";
// errors
import { ERROR_UNAUTHORIZED, ERROR_WRONG_RESPONSE } from "./apiErrors";
// conversion
import { INarrative, INarrativeShort, INarrativeChapter } from "src/@types/narrative";
import { convertDataToNarrative, convertDataToNarrativeShort } from "./conversions";

// ---------- NARRATIVES -------------

export const apiGetNarrative = (narrativeId: number): Promise<INarrative> => {
	return axios
		.get(API_NARRATIVES + `/${narrativeId}`, { withCredentials: true })
		.then((response) => {
			if (response.data) return convertDataToNarrative(response.data);
			else throw ERROR_WRONG_RESPONSE;
		})
		.catch((error) => {
			console.error(error);
			throw error.message;
		});
};

export const apiGetAllNarratives = (workspaceId: number): Promise<INarrativeShort[]> => {
	return axios
		.get(API_NARRATIVES + `/workspace/${workspaceId}`, { withCredentials: true })
		.then((response) => {
			if (response.data) {
				let narratives: INarrativeShort[] = [];
				for (let x in response.data) narratives.push(convertDataToNarrativeShort(response.data[x]));
				return narratives;
			} else return [];
		})
		.catch((error) => {
			console.error(error);
			throw error.message;
		});
};

export const apiCreateNarrativeAndChapters = async (
	workspaceId: number,
	narrative: Partial<INarrative>,
	chapters: Partial<INarrativeChapter>[]
): Promise<INarrative> => {
	// NARRATIVE first
	// we have to split Narrative & Chapters as we can't transfer complex objects through form data.
	const formData = new FormData();
	formData.append("workspace_id", workspaceId.toString());
	if (!!narrative.name) formData.append("name", narrative.name);
	if (!!narrative.introText) formData.append("introText", narrative.introText);
	if (!!narrative.image) {
		const image = narrative.image;
		if (image instanceof File) formData.append("image", narrative.image);
	}
	// AXIOS
	const createdNarrative = await axios
		.post(API_NARRATIVES, formData, { withCredentials: true })
		.then((response) => {
			if (response.data) {
				return convertDataToNarrativeShort(response.data);
			} else {
				console.error("Missing Data in the response");
				throw ERROR_WRONG_RESPONSE;
			}
		})
		.catch((error) => {
			console.error(error);
			// we don't know :'(
			throw error.message;
		});
	// if the Narrative has not been returned then throw error
	if (!createdNarrative) throw new Error("An error occurred while creating the narrative.");
	// CHAPTERS save all of them
	if (!!chapters && chapters.length > 0) {
		await Promise.all(
			chapters.map(async (chapter, index) => {
				const formDataChapter = new FormData();
				formDataChapter.append("narrative_id", createdNarrative.id.toString());
				if (!!chapter.title) formDataChapter.append("title", chapter.title);
				if (!!chapter.text) formDataChapter.append("text", chapter.text);
				formDataChapter.append("rank", index.toString());
				if (!!chapter.image) {
					const image = chapter.image;
					if (image instanceof File) formDataChapter.append("image", chapter.image);
				}
				if (!!chapter.elements && chapter.elements.length > 0)
					chapter.elements.forEach((elementId) => {
						formDataChapter.append("elements[]", elementId.toString());
					});
				// send data to back
				return axios
					.post(API_NARRATIVES + "/chapter", formDataChapter, { withCredentials: true })
					.catch((error) => {
						console.error(error);
						// we don't know :'(
						throw error.message;
					});
			})
		);
	}
	// get the new Narrative with all info
	return apiGetNarrative(createdNarrative.id);
};

export const apiUpdateNarrativeAndChapters = async (
	narrative: INarrative,
	chaptersToDelete: INarrativeChapter[]
): Promise<INarrative> => {
	// NARRATIVE first
	// we have to split Narrative & Chapters as we can't transfer complex objects through form data.
	const formData = new FormData();
	formData.append("id", narrative.id.toString());
	if (!!narrative.name) formData.append("name", narrative.name);
	if (!!narrative.introText) formData.append("introText", narrative.introText);
	if (!!narrative.image) {
		const image = narrative.image;
		if (image instanceof File) formData.append("image", narrative.image);
	}
	// AXIOS
	const updatedNarrative = await axios
		.patch(API_NARRATIVES, formData, { withCredentials: true })
		.then((response) => {
			if (response.data) {
				return convertDataToNarrativeShort(response.data);
			} else {
				console.error("Missing Data in the response");
				throw ERROR_WRONG_RESPONSE;
			}
		})
		.catch((error) => {
			console.error(error);
			// we don't know :'(
			throw error.message;
		});
	// if the Narrative has not been returned then throw error
	if (!updatedNarrative) throw new Error("An error occurred while editing the narrative.");
	// CHAPTERS save all of them
	if (!!narrative.chapters && narrative.chapters.length > 0) {
		await Promise.all(
			narrative.chapters.map(async (chapter, index) => {
				if (chapter.id === -1) {
					// create chapter
					const formDataChapter = new FormData();
					formDataChapter.append("narrative_id", updatedNarrative.id.toString());
					if (!!chapter.title) formDataChapter.append("title", chapter.title);
					if (!!chapter.text) formDataChapter.append("text", chapter.text);
					formDataChapter.append("rank", index.toString());
					if (!!chapter.image) {
						const image = chapter.image;
						if (image instanceof File) formDataChapter.append("image", chapter.image);
					}
					if (!!chapter.elements && chapter.elements.length > 0)
						chapter.elements.forEach((elementId) => {
							formDataChapter.append("elements[]", elementId.toString());
						});
					// send data to back
					return axios
						.post(API_NARRATIVES + "/chapter", formDataChapter, { withCredentials: true })
						.catch((error) => {
							console.error(error);
							// we don't know :'(
							throw error.message;
						});
				} else {
					// edit chapter
					const formDataChapter = new FormData();
					formDataChapter.append("id", chapter.id.toString());
					if (!!chapter.title) formDataChapter.append("title", chapter.title);
					if (!!chapter.text) formDataChapter.append("text", chapter.text);
					formDataChapter.append("rank", index.toString());
					if (!!chapter.image) {
						const image = chapter.image;
						if (image instanceof File) formDataChapter.append("image", chapter.image);
					}
					if (!!chapter.elements && chapter.elements.length > 0)
						chapter.elements.forEach((elementId) => {
							formDataChapter.append("elements[]", elementId.toString());
						});
					// send data to back
					return axios
						.patch(API_NARRATIVES + "/chapter", formDataChapter, { withCredentials: true })
						.catch((error) => {
							console.error(error);
							// we don't know :'(
							throw error.message;
						});
				}
			})
		);
	}
	// deleted chapters
	if (!!chaptersToDelete && chaptersToDelete.length > 0) {
		await Promise.all(
			chaptersToDelete.map(async (chapter) => {
				// delete chapter
				return axios
					.delete(API_NARRATIVES + `/chapter/${chapter.id}`, { withCredentials: true })
					.catch((error) => {
						console.error(error);
						// we don't know :'(
						throw error.message;
					});
			})
		);
	}
	// get the new Narrative with all info
	return apiGetNarrative(updatedNarrative.id);
};

export const apiDeleteNarrative = (id: number) => {
	// AXIOS
	return axios.delete(API_NARRATIVES + `/${id}`, { withCredentials: true }).catch((error) => {
		console.error(error);
		// we don't know :'(
		throw error.message;
	});
};

// -------------------- COUNT -------------------------------

/**
 * ADMIN Get the count of all narratives
 * @returns
 * @error ERROR_UNAUTHORIZED
 * @error error.message
 */
export const apiAdminNarrativeCount = (): Promise<number> => {
	return axios
		.get(API_NARRATIVES_COUNT, { withCredentials: true })
		.then((response) => {
			if (response.data) {
				try {
					const count = parseInt(response.data);
					if (isNaN(count)) throw Error("Can't convert response.data to integer");
					return count;
				} catch (error) {
					console.error("Converting data to Count: FAILED >> " + error);
					throw ERROR_WRONG_RESPONSE;
				}
			} else {
				console.error("Missing Data in the response");
				throw ERROR_WRONG_RESPONSE;
			}
		})
		.catch((error) => {
			console.error(error);
			if (!!error.statusCode && error.statusCode === 401) throw ERROR_UNAUTHORIZED;
			else throw error.message;
		});
};
