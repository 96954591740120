import { axiosInstance as axios } from "./axiosInstance";
// paths
import { API_CHARACTERS, API_CHARACTERS_COUNT, API_INTERVIEWS, API_INTERVIEWS_COUNT } from "./apiPaths";
// errors
import { ERROR_UNAUTHORIZED, ERROR_WRONG_RESPONSE } from "./apiErrors";
// conversion
import { IInterview } from "src/@types/interview";
import { convertDataToInterview } from "./conversions";

// ---------- INTERVIEWS -------------

export const apiGetAllInterviews = (workspaceId: number): Promise<IInterview[]> => {
	return axios
		.get(API_CHARACTERS + `/getAll/${workspaceId}`, { withCredentials: true })
		.then((response) => {
			if (response.data) {
				let interviews: IInterview[] = [];
				for (let x in response.data) interviews.push(convertDataToInterview(response.data[x]));
				return interviews;
			} else return [];
		})
		.catch((error) => {
			console.error(error);
			throw error.message;
		});
};

export const apiGetInterview = (interviewId: number): Promise<IInterview> => {
	return axios
		.get(API_INTERVIEWS + `/get/${interviewId}`, { withCredentials: true })
		.then((response) => {
			if (response.data) {
				return convertDataToInterview(response.data);
			} else {
				console.error("Missing Data in the response");
				throw ERROR_WRONG_RESPONSE;
			}
		})
		.catch((error) => {
			console.error(error);
			throw error.message;
		});
};

export const apiCreateCharacter = async (
	workspaceId: number,
	interview: Partial<IInterview>
): Promise<IInterview> => {
	// NARRATIVE first
	// we have to split Narrative & Chapters as we can't transfer complex objects through form data.
	const formData = new FormData();
	formData.append("workspace_id", workspaceId.toString());
	if (interview.name) formData.append("character_name", interview.name);
	if (interview.language) formData.append("language", interview.language);
	if (interview.tone) formData.append("tone", interview.tone);
	if (interview.audienceAge)
		formData.append("audience_age", interview.audienceAge.toLocaleString());
	if (!!interview.image) {
		const image = interview.image;
		if (image instanceof File) formData.append("image", interview.image);
	}
	// AXIOS
	return await axios
		.post(API_CHARACTERS + "/create", formData, { withCredentials: true })
		.then((response) => {
			if (response.data) {
				return convertDataToInterview(response.data);
			} else {
				console.error("Missing Data in the response");
				throw ERROR_WRONG_RESPONSE;
			}
		})
		.catch((error) => {
			console.error(error);
			// we don't know :'(
			throw error.message;
		});
};

export const apiCreateInterview = async (
	workspaceId: number,
	characterId: number
): Promise<IInterview> => {
	// NARRATIVE first
	// we have to split Narrative & Chapters as we can't transfer complex objects through form data.
	const formData = new FormData();
	formData.append("workspace_id", workspaceId.toString());
	formData.append("character_id", characterId.toString());
	// AXIOS
	return await axios
		.post(API_INTERVIEWS + "/create", formData, { withCredentials: true })
		.then((response) => {
			if (response.data) {
				return convertDataToInterview(response.data);
			} else {
				console.error("Missing Data in the response");
				throw ERROR_WRONG_RESPONSE;
			}
		})
		.catch((error) => {
			console.error(error);
			// we don't know :'(
			throw error.message;
		});
};

export const apiSendMessageToInterview = async (
	interviewId: number,
	message: string
): Promise<IInterview> => {
	// NARRATIVE first
	// we have to split Narrative & Chapters as we can't transfer complex objects through form data.
	const formData = new FormData();
	formData.append("interview_id", interviewId.toString());
	formData.append("message", message);
	// AXIOS
	return await axios
		.post(API_INTERVIEWS + "/sendMessage", formData, { withCredentials: true })
		.then((response) => {
			if (response.data) {
				return convertDataToInterview(response.data);
			} else {
				console.error("Missing Data in the response");
				throw ERROR_WRONG_RESPONSE;
			}
		})
		.catch((error) => {
			console.error(error);
			// we don't know :'(
			throw error.message;
		});
};

export const apiDeleteCharacter = (id: number) => {
	// AXIOS
	return axios
		.delete(`${API_CHARACTERS}/delete/${id.toLocaleString()}`, { withCredentials: true })
		.catch((error) => {
			console.error(error);
			// we don't know :'(
			throw error.message;
		});
};

// -------------------- COUNT -------------------------------

/**
 * ADMIN Get the count of all interviews
 * @returns
 * @error ERROR_UNAUTHORIZED
 * @error error.message
 */
export const apiAdminCharacterCount = (): Promise<number> => {
	return axios
		.get(API_CHARACTERS_COUNT, { withCredentials: true })
		.then((response) => {
			if (response.data) {
				try {
					const count = parseInt(response.data);
					if (isNaN(count)) throw Error("Can't convert response.data to integer");
					return count;
				} catch (error) {
					console.error("Converting data to Count: FAILED >> " + error);
					throw ERROR_WRONG_RESPONSE;
				}
			} else {
				console.error("Missing Data in the response");
				throw ERROR_WRONG_RESPONSE;
			}
		})
		.catch((error) => {
			console.error(error);
			if (!!error.statusCode && error.statusCode === 401) throw ERROR_UNAUTHORIZED;
			else throw error.message;
		});
};


/**
 * ADMIN Get the count of all interviews
 * @returns
 * @error ERROR_UNAUTHORIZED
 * @error error.message
 */
export const apiAdminInterviewCount = (): Promise<number> => {
	return axios
		.get(API_INTERVIEWS_COUNT, { withCredentials: true })
		.then((response) => {
			if (response.data) {
				try {
					const count = parseInt(response.data);
					if (isNaN(count)) throw Error("Can't convert response.data to integer");
					return count;
				} catch (error) {
					console.error("Converting data to Count: FAILED >> " + error);
					throw ERROR_WRONG_RESPONSE;
				}
			} else {
				console.error("Missing Data in the response");
				throw ERROR_WRONG_RESPONSE;
			}
		})
		.catch((error) => {
			console.error(error);
			if (!!error.statusCode && error.statusCode === 401) throw ERROR_UNAUTHORIZED;
			else throw error.message;
		});
};
