import { Navigate, useRoutes } from "react-router-dom";
// auth
import AuthGuard from "src/auth/AuthGuard";
import GuestGuard from "src/auth/GuestGuard";
import SuperAdminGuard from "src/auth/SuperAdminGuard";
// layouts
import MainLayout from "src/layouts/main";
import SimpleLayout from "src/layouts/simple";
import CompactLayout from "src/layouts/compact";
import DashboardLayout from "src/layouts/dashboard";
import AdminLayout from "src/layouts/admin/AdminLayout";
// config
import { PATH_AFTER_ADMIN_LOGIN, PATH_AFTER_LOGIN } from "src/config-global";
//
import {
	// Auth
	LoginPage,
	RegisterPage,
	VerifyCodePage,
	NewPasswordPage,
	ResetPasswordPage,
	// Admin
	AdminLoginPage,
	AdminDashboardPage,
	AdminUsersPage,
	AdminEventsPage,
	// Dashboard: User
	UserPage,
	UserAccountPage,
	UserContactsPage,
	// Dashboard: Workspace
	WorkspacePage,
	WorkspaceListPage,
	WorkspaceNewPage,
	WorkspaceSettingsPage,
	WorkspaceMembersPage,
	WorkspaceInformationPage,
	// Dashboard: Exploration
	ExplorationPage,
	ExplorationElementsPage,
	ExplorationRelationsPage,
	ExplorationGeoMapPage,
	ExplorationTimelinesPage,
	// Dashboard: Element
	ElementEditPage,
	ElementViewPage,
	// Dashboard: History
	HistoryPage,
	HistoryNarrativesPage,
	HistoryInterviewsPage,
	// Dashboard: Narratives
	NarrativeNewPage,
	NarrativeEditPage,
	NarrativeViewPage,
	// Dashboard: Interviews
	InterviewNewPage,
	InterviewViewPage,
	// other necessary
	BlankPage,
	PermissionDeniedPage,
	// main public
	Page500,
	Page403,
	Page404,
	HomePage,
	TargetPage,
	AboutPage,
	Contact,
	PricingPage,
	PaymentPage,
	ComingSoonPage,
	MaintenancePage,
	PrivacyPolicyPage,
	TermsAndConditionsPage,
} from "./elements";

// ----------------------------------------------------------------------

export default function Router() {
	return useRoutes([
		// Auth
		{
			path: "auth",
			children: [
				{
					path: "login",
					element: (
						<GuestGuard>
							<LoginPage />
						</GuestGuard>
					),
				},
				{
					path: "register",
					element: (
						<GuestGuard>
							<RegisterPage />
						</GuestGuard>
					),
				},
				{ path: "login-unprotected", element: <LoginPage /> },
				{
					element: <CompactLayout />,
					children: [
						{ path: "reset-password", element: <ResetPasswordPage /> },
						{ path: "new-password", element: <NewPasswordPage /> },
						{ path: "verify", element: <VerifyCodePage /> },
					],
				},
			],
		},

		// Admin
		{
			path: "admin",
			children: [
				{
					path: "login",
					element: (
						<GuestGuard>
							<AdminLoginPage />
						</GuestGuard>
					),
				},
				{
					path: "dashboard",
					element: (
						<AuthGuard>
							<SuperAdminGuard>
								<AdminLayout />
							</SuperAdminGuard>
						</AuthGuard>
					),
					children: [
						{ element: <Navigate to={PATH_AFTER_ADMIN_LOGIN} replace />, index: true },
						{ path: "main", element: <AdminDashboardPage /> },
						{ path: "users", element: <AdminUsersPage /> },
						{ path: "events", element: <AdminEventsPage /> },
					],
				},
			],
		},

		// Dashboard
		{
			path: "dashboard",
			element: (
				<AuthGuard>
					<DashboardLayout />
				</AuthGuard>
			),
			children: [
				{ element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
				// user
				{
					path: "user",
					children: [
						{ element: <UserPage />, index: true },
						{ path: "account", element: <UserAccountPage /> },
						{ path: "contacts", element: <UserContactsPage /> },
					],
				},
				// workspace
				{
					path: "workspace",
					children: [
						{ element: <Navigate to="/dashboard/workspace/cards" replace />, index: true },
						{ path: "list", element: <WorkspaceListPage /> },
						{ path: "new", element: <WorkspaceNewPage /> },
						{
							path: ":wsId",
							children: [
								{ element: <WorkspacePage />, index: true },
								// SETTINGS
								{ path: "settings", element: <WorkspaceSettingsPage /> },
								{ path: "settings/members", element: <WorkspaceMembersPage /> },
								{ path: "settings/information", element: <WorkspaceInformationPage /> },
								// EXPLORATION
								{ path: "exploration", element: <ExplorationPage /> },
								{ path: "exploration/elements", element: <ExplorationElementsPage />, index: true },
								{ path: "exploration/relations", element: <ExplorationRelationsPage />, index: true },
								{ path: "exploration/geomap", element: <ExplorationGeoMapPage /> },
								{ path: "exploration/timelines", element: <ExplorationTimelinesPage /> },
								// ELEMENTS
								{
									path: "element",
									element: <Navigate to="/dashboard/workspace/:wsId/exploration" replace />,
								},
								{ path: "element/:elId/edit", element: <ElementEditPage /> },
								{ path: "element/:elId/view", element: <ElementViewPage /> },
								// HISTORY
								{ path: "history", element: <HistoryPage /> },
								{ path: "history/narratives", element: <HistoryNarrativesPage />, index: true },
								{ path: "history/interviews", element: <HistoryInterviewsPage />, index: true },
								// NARRATIVES
								{ path: "narrative/new", element: <NarrativeNewPage /> },
								{ path: "narrative/:naId/edit", element: <NarrativeEditPage /> },
								{ path: "narrative/:naId/view", element: <NarrativeViewPage /> },
								// INTERVIEWS
								{ path: "interview/new", element: <InterviewNewPage /> },
								{ path: "interview/:inId/view", element: <InterviewViewPage /> },
							],
						},
					],
				},
				// other necessary
				{ path: "permission-denied", element: <PermissionDeniedPage /> },
				{ path: "blank", element: <BlankPage /> },
			],
		},

		// Main Routes
		{
			element: <MainLayout />,
			children: [
				{ element: <HomePage />, index: true },
				{ path: "target", element: <TargetPage /> },
				{ path: "about-us", element: <AboutPage /> },
				{ path: "contact-us", element: <Contact /> },
				{ path: "pricing", element: <PricingPage /> },
			],
		},
		{
			element: <SimpleLayout />,
			children: [
				{ path: "payment", element: <PaymentPage /> },
				{ path: "terms-and-conditions", element: <TermsAndConditionsPage /> },
				{ path: "privacy-policy", element: <PrivacyPolicyPage /> },
			],
		},
		{
			element: <CompactLayout />,
			children: [
				{ path: "coming-soon", element: <ComingSoonPage /> },
				{ path: "maintenance", element: <MaintenancePage /> },
				{ path: "500", element: <Page500 /> },
				{ path: "404", element: <Page404 /> },
				{ path: "403", element: <Page403 /> },
			],
		},
		{ path: "*", element: <Navigate to="/404" replace /> },
	]);
}
