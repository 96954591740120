import { HOST_API_URL, MAX_LENGTH } from "src/config-global";
// geojson
import { Feature, FeatureCollection } from "geojson";
import { isValidGeoJSONFeature } from "src/utils/formatGeoData";
// Const
import { ERROR_WRONG_RESPONSE } from "./apiErrors";
// TYPES
import { IUser, IUserFull } from "../@types/user";
import { IAccessRight, IWorkspace, IWorkspaceWithOwner } from "../@types/workspace";
import { IContact } from "src/@types/contact";
import { IDate, IDates } from "src/@types/date";
import { IElement, IElementShort, IGraphPosition, IRelation } from "src/@types/element";
import { ITask } from "src/@types/task";
import { INarrative, INarrativeChapter, INarrativeShort } from "src/@types/narrative";
import { IEvent } from "src/@types/event";
import {
	IWikiSearchResult,
	IWikiRelation,
	IWikiPage,
	WikiCategoriesForPeriod,
	IWikiCategory,
} from "src/@types/wiki";
import { IInterview, IInterviewMessage } from "src/@types/interview";

/************************/
/** CONVERSION HELPERS **/
/************************/

// -----------------------------------------------------------------------
// USERS

export const convertDataToUser = (data: any): IUser => {
	try {
		const id = parseInt(data.id);
		const firstName = data.first_name;
		const lastName = data.last_name;
		const displayName = data.display_name;
		const email = data.email;
		const friendCode = data.friend_code;
		const countryCode = data.country_code ?? null;
		const image = !!data.image ? HOST_API_URL + data.image : null;
		const about = data.about ?? null;
		const currentWorkspaceId = data.current_workspace_id ?? null;
		const personalWorkspaceId = data.personal_workspace_id ?? null;
		const role = data.role;
		const adminRole = data.admin_role;
		return {
			id,
			firstName,
			lastName,
			displayName,
			countryCode,
			image,
			email,
			friendCode,
			about,
			currentWorkspaceId,
			personalWorkspaceId,
			role,
			adminRole,
		} as IUser;
	} catch (error) {
		console.error("Converting data to User: FAILED >> " + error);
		throw ERROR_WRONG_RESPONSE;
	}
};

export const convertDataToUserFull = (data: any): IUserFull => {
	try {
		const id = parseInt(data.id);
		const firstName = data.first_name;
		const lastName = data.last_name;
		const displayName = data.display_name;
		const email = data.email;
		const friendCode = data.friend_code;
		const countryCode = data.country_code ?? null;
		const image = !!data.image ? HOST_API_URL + data.image : null;
		const about = data.about ?? null;
		const currentWorkspaceId = data.current_workspace_id ?? null;
		const personalWorkspaceId = data.personal_workspace_id ?? null;
		// additional
		const activated = data.activated;
		const emailValidated = data.email_validated;
		const tmpEmail = data.tmp_email;
		const resetExpiresAt = !!data.reset_expires_at ? new Date(data.reset_expires_at) : undefined;
		const role = data.role;
		const adminRole = data.admin_role;
		const connections = parseInt(data.connections);
		const lastLoginDate = !!data.last_login_date ? new Date(data.last_login_date) : undefined;
		const createdAt = new Date(data.created_at);
		const updatedAt = new Date(data.updated_at);
		const workspaces = data.workspaces;
		const elements = data.elements;
		const relations = data.relations;
		const narratives = data.narratives;
		const friends = data.friends;
		// return
		return {
			id,
			firstName,
			lastName,
			displayName,
			countryCode,
			image,
			email,
			friendCode,
			about,
			currentWorkspaceId,
			personalWorkspaceId,
			activated,
			emailValidated,
			tmpEmail,
			resetExpiresAt,
			role,
			adminRole,
			connections,
			lastLoginDate,
			createdAt,
			updatedAt,
			workspaces,
			elements,
			relations,
			narratives,
			friends,
		} as IUserFull;
	} catch (error) {
		console.error("Converting data to User: FAILED >> " + error);
		throw ERROR_WRONG_RESPONSE;
	}
};

// -----------------------------------------------------------------------
// WORKSPACES

export const convertDataToWorkspace = (data: any): IWorkspace => {
	try {
		const id = parseInt(data.id);
		const name = data.name;
		const description = data.description;
		const image = !!data.image ? HOST_API_URL + data.image : undefined;
		const language = data.language;
		const audienceAge = data.audience_age;
		const isPersonal = data.is_personal;
		const rightLevel = data.right_level;
		const ownerId = data.owner_id;
		const createdAt = new Date(data.created_at);
		const updatedAt = new Date(data.updated_at);

		return {
			id,
			name,
			description,
			image,
			language,
			audienceAge,
			isPersonal,
			rightLevel,
			ownerId,
			createdAt,
			updatedAt,
		} as IWorkspace;
	} catch (error) {
		console.error("Converting data to Workspace: FAILED >> " + error);
		throw ERROR_WRONG_RESPONSE;
	}
};

export const convertDataToWorkspaceWithOwner = (data: any): IWorkspaceWithOwner => {
	try {
		const id = parseInt(data.id);
		const name = data.name;
		const description = data.description;
		const image = !!data.image ? HOST_API_URL + data.image : undefined;
		const language = data.language;
		const audienceAge = data.audience_age;
		const isPersonal = data.is_personal;
		const isPublic = data.is_public;
		const rightLevel = data.right_level;
		const ownerId = data.owner_id;
		const ownerName = data.owner_name;
		const ownerImage = !!data.owner_image ? HOST_API_URL + data.owner_image : undefined;
		const elements = data.elements;
		const relations = data.relations;
		const narratives = data.narratives;
		const members = data.members;
		const createdAt = new Date(data.created_at);
		const updatedAt = new Date(data.updated_at);
		return {
			id,
			name,
			description,
			image,
			language,
			audienceAge,
			isPersonal,
			isPublic,
			rightLevel,
			ownerId,
			ownerName,
			ownerImage,
			elements,
			relations,
			narratives,
			members,
			createdAt,
			updatedAt,
		} as IWorkspaceWithOwner;
	} catch (error) {
		console.error("Converting data to WorkspaceWithOwner: FAILED >> " + error);
		throw ERROR_WRONG_RESPONSE;
	}
};

export const convertDataToAccessRight = (data: any): IAccessRight => {
	try {
		const id = parseInt(data.id);
		const rightLevel = data.level;
		const userId = parseInt(data.user.id);
		const userName = data.user.name;
		const userImage = !!data.user.image ? HOST_API_URL + data.user.image : undefined;
		const userFriendCode = data.user.friend_code;
		const createdAt = new Date(data.created_at);
		const updatedAt = new Date(data.updated_at);
		return {
			id,
			rightLevel,
			userId,
			userName,
			userImage,
			userFriendCode,
			createdAt,
			updatedAt,
		} as IAccessRight;
	} catch (error) {
		console.error("Converting data to AccessRights: FAILED >> " + error);
		throw ERROR_WRONG_RESPONSE;
	}
};

// -----------------------------------------------------------------------
// CONTACTS

export const convertDataToContact = (data: any): IContact => {
	try {
		const id = parseInt(data.id);
		const userId = parseInt(data.friend_id);
		const name = data.friend_name;
		const image = !!data.friend_image ? HOST_API_URL + data.friend_image : undefined;
		const message = data.message;
		const statusCode = data.status;
		const friendCode = data.friend_code;
		const createdAt = new Date(data.created_at);
		const updatedAt = new Date(data.updated_at);
		return {
			id,
			userId,
			name,
			image,
			message,
			statusCode,
			friendCode,
			createdAt,
			updatedAt,
		} as IContact;
	} catch (error) {
		console.error("Converting data to Contact: FAILED >> " + error);
		throw ERROR_WRONG_RESPONSE;
	}
};

// -----------------------------------------------------------------------
// ELEMENT

export const convertDataToElement = (data: any): IElement => {
	try {
		const id = parseInt(data.id);
		const name = data.name;
		const otherNames = !!data.other_names ? data.other_names : undefined;
		let image = !!data.image ? data.image : undefined;
		if (data.image && !data.image.startsWith("http")) image = HOST_API_URL + image;
		const description = !!data.description ? data.description : undefined;
		const tags = !!data.tags ? data.tags.filter((tag: string) => !!tag && tag !== null).sort() : [];
		// IDates
		let iStartDate: IDate | null = null;
		let iEndDate: IDate | null = null;
		let dates: IDates;
		if (!!data.start_date_str)
			iStartDate = convertDataToIDate(data.start_date_str, data.start_date_precision);
		if (!!data.end_date_str)
			iEndDate = convertDataToIDate(data.end_date_str, data.end_date_precision);
		const period = data.period;
		dates = {
			startDate: iStartDate,
			endDate: iEndDate,
			period: period,
		};
		// geo
		const location =
			!!data.location && isValidGeoJSONFeature(data.location)
				? (JSON.parse(data.location) as Feature)
				: undefined;
		const mapData = !!data.map_data ? (JSON.parse(data.map_data) as FeatureCollection) : undefined;
		// relations >>> for now, we are merging origin and destination (simpler for user)
		const relations = !!data.relations
			? data.relations.map((rel: any) => convertDataToRelation(rel))
			: [];
		// relations >>> for now, we are merging origin and destination (simpler for user)
		const sources = !!data.sources
			? data.sources.filter((source: string) => !!source && source !== null).sort()
			: [];
		// wiki
		const wikiId = !!data.wiki_id ? data.wiki_id : undefined;
		// tech
		const createdAt = new Date(data.created_at);
		const updatedAt = new Date(data.updated_at);
		return {
			id,
			name,
			otherNames,
			description,
			image,
			tags,
			dates,
			location,
			mapData,
			relations,
			sources,
			wikiId,
			createdAt,
			updatedAt,
		} as IElement;
	} catch (error) {
		console.error("Converting data to Element: FAILED >> " + error);
		throw ERROR_WRONG_RESPONSE;
	}
};

export const convertDataToElementShort = (data: any): IElementShort => {
	try {
		const id = parseInt(data.id);
		const name = data.name;
		let image = !!data.image ? data.image : undefined;
		if (data.image && !data.image.startsWith("http")) image = HOST_API_URL + image;
		const tags = !!data.tags ? data.tags.filter((tag: string) => !!tag && tag !== null).sort() : [];
		// IDates
		let iStartDate: IDate | null = null;
		let iEndDate: IDate | null = null;
		let dates: IDates;
		if (!!data.start_date_str)
			iStartDate = convertDataToIDate(data.start_date_str, data.start_date_precision);
		if (!!data.end_date_str)
			iEndDate = convertDataToIDate(data.end_date_str, data.end_date_precision);
		const period = data.period; // === "true" ? true : false;
		dates = {
			startDate: iStartDate,
			endDate: iEndDate,
			period: period,
		};
		// geo
		const location =
			!!data.location && isValidGeoJSONFeature(data.location)
				? (JSON.parse(data.location) as Feature)
				: undefined;
		const mapData = !!data.map_data ? (JSON.parse(data.map_data) as FeatureCollection) : undefined;
		// wiki id
		const wikiId = !!data.wiki_id ? data.wiki_id : undefined;
		// tech
		const createdAt = new Date(data.created_at);
		const updatedAt = new Date(data.updated_at);
		// return
		return {
			id,
			name,
			image,
			tags,
			dates,
			location,
			mapData,
			wikiId,
			createdAt,
			updatedAt,
		} as IElementShort;
	} catch (error) {
		console.error("Converting data to ElementShort: FAILED >> " + error);
		throw ERROR_WRONG_RESPONSE;
	}
};

// -----------------------------------------------------------------------
// DATE

export const convertDataToIDate = (dateStr: string, precision: string): IDate => {
	const year: number =
		dateStr.slice(0, 1) === "-"
			? parseInt(dateStr.slice(0, 5)) // handle the 0 which is wrong in JS
			: parseInt(dateStr.slice(1, 5));
	const month: number = parseInt(dateStr.slice(6, 8)) - 1; // month in JS are 0->11
	const day: number = parseInt(dateStr.slice(9, 11));
	const date = new Date(year, month, day);
	if (year < 100 && year > 0) date.setFullYear(year);
	return {
		date,
		dateStr,
		precision,
	} as IDate;
};

// -----------------------------------------------------------------------
// RELATIONS

export const convertDataToRelation = (data: any): IRelation => {
	const id = parseInt(data.id);
	const originId = parseInt(data.origin_id);
	const link = data.link;
	const destinationId = parseInt(data.destination_id);
	// return
	return {
		id,
		originId,
		link,
		destinationId,
	} as IRelation;
};

// -----------------------------------------------------------------------
// GRAPH POSITIONS

export const convertDataToGraphPosition = (data: any): IGraphPosition => {
	const id = parseInt(data.id);
	const x = !!data.position_x ? parseInt(data.position_x) : undefined;
	const y = !!data.position_y ? parseInt(data.position_y) : undefined;
	// return
	return {
		id,
		x,
		y,
	} as IGraphPosition;
};

// -----------------------------------------------------------------------
// GEO LOCATIONS

export const convertDataToGeoloc = (data: any): IGraphPosition => {
	const id = parseInt(data.id);
	const x = !!data.position_x ? parseInt(data.position_x) : undefined;
	const y = !!data.position_y ? parseInt(data.position_y) : undefined;
	// return
	return {
		id,
		x,
		y,
	} as IGraphPosition;
};

// -----------------------------------------------------------------------
// TASKS

export const convertDataToTask = (data: any): ITask => {
	const id = parseInt(data.id);
	const name = data.name;
	const description = !!data.description ? data.description : undefined;
	const status = data.status;
	return { id, name, description, status } as ITask;
};

// -----------------------------------------------------------------------
// NARRATIVES

export const convertDataToNarrativeChapter = (data: any): INarrativeChapter => {
	try {
		const id = parseInt(data.id);
		const title = data.title;
		const image = !!data.image ? HOST_API_URL + data.image : undefined;
		const text = !!data.text ? data.text : undefined;
		const rank = !!data.rank || data.rank === 0 ? data.rank : undefined;
		const elements = !!data.elements ? data.elements.map((el: any) => el.id) : [];
		return { id, title, image, text, rank, elements } as INarrativeChapter;
	} catch (error) {
		console.error("Converting data to NarrativeChapter: FAILED >> " + error);
		throw ERROR_WRONG_RESPONSE;
	}
};

export const convertDataToNarrative = (data: any): INarrative => {
	try {
		const id = parseInt(data.id);
		const name = data.name;
		const image = !!data.image ? HOST_API_URL + data.image : undefined;
		const introText = !!data.introText ? data.introText : undefined;
		const chapters = !!data.chapters
			? data.chapters.map((chapterData: any) => convertDataToNarrativeChapter(chapterData))
			: [];
		chapters.sort((a: INarrativeChapter, b: INarrativeChapter) => a.rank - b.rank);
		// tech
		const createdAt = new Date(data.created_at);
		const updatedAt = new Date(data.updated_at);
		// return
		return { id, name, image, introText, chapters, createdAt, updatedAt } as INarrative;
	} catch (error) {
		console.error("Converting data to Narrative: FAILED >> " + error);
		throw ERROR_WRONG_RESPONSE;
	}
};

export const convertDataToNarrativeShort = (data: any): INarrativeShort => {
	try {
		const id = parseInt(data.id);
		const name = data.name;
		const image = !!data.image ? HOST_API_URL + data.image : undefined;
		const introText = !!data.introText ? data.introText : undefined;
		// tech
		const createdAt = new Date(data.created_at);
		const updatedAt = new Date(data.updated_at);
		// return
		return { id, name, image, introText, createdAt, updatedAt } as INarrativeShort;
	} catch (error) {
		console.error("Converting data to Narrative: FAILED >> " + error);
		throw ERROR_WRONG_RESPONSE;
	}
};

// -----------------------------------------------------------------------
// NARRATIVES

export const convertDataToEvent = (data: any): IEvent => {
	try {
		const id = parseInt(data.id);
		const ip = data.ip;
		const name = data.name;
		const distinctUserId = data.distinct_user_id ?? null;
		const userId = data.user_id ?? null;
		const url = data.url;
		const location = data.location;
		const properties = data.properties ?? null;
		// tech
		const createdAt = new Date(data.created_at);
		const updatedAt = new Date(data.updated_at);
		return {
			id,
			ip,
			name,
			distinctUserId,
			userId,
			url,
			location,
			properties,
			createdAt,
			updatedAt,
		} as IEvent;
	} catch (error) {
		console.error("Converting data to NarrativeChapter: FAILED >> " + error);
		throw ERROR_WRONG_RESPONSE;
	}
};

// -----------------------------------------------------------------------
// WIKI

export const convertDataToWikiSearchResult = (data: any): IWikiSearchResult => {
	try {
		const id = data.id;
		const name = data.label;
		const description = data.description;
		const category = data.category.label;
		return {
			id,
			name,
			description,
			category,
		} as IWikiSearchResult;
	} catch (error) {
		console.error("Converting data to WikiSearchResult: FAILED >> " + error);
		throw ERROR_WRONG_RESPONSE;
	}
};

export const convertDataToWikiRelations = (data: any): IWikiRelation[] => {
	try {
		const relations: IWikiRelation[] = [];
		for (const relation in data) {
			const transformedItems = data[relation].map((item: any) => ({
				id: item.id,
				name: item.value,
				relation: relation,
				idNum: parseInt((item.id as string).slice(1)),
			}));
			relations.push(...transformedItems);
		}
		// Filtrage pour ne garder que les éléments avec des 'id' uniques
		const uniqueRelations = relations.filter((rel, index, array) => {
			// On vérifie si l'indice courant est le premier où cet 'id' apparaît
			return array.findIndex((i) => i.id === rel.id) === index;
		});
		// Filtrage pour ne garder que les éléments qui ont un vrai label
		const validRelations = uniqueRelations.filter((rel) => rel.id !== rel.name);
		// sort & return
		return validRelations.sort((a, b) => a.idNum - b.idNum);
	} catch (error) {
		console.error("Converting data to WikiRelations: FAILED >> " + error);
		throw ERROR_WRONG_RESPONSE;
	}
};

export const convertWikiDateToIDate = (dateStr: string | null): IDate | null => {
	try {
		if (!dateStr) return null;
		// check regex
		const regex = new RegExp(/^[+-]\d{4}-\d{2}-\d{2}$/);
		if (!regex.test(dateStr)) return null;
		// find the number
		const year = dateStr.startsWith("-")
			? 0 - parseInt(dateStr.slice(1, 5))
			: parseInt(dateStr.slice(1, 5));
		if (year < -4500 || year > 9999) return null; // for the backend -> https://www.postgresql.org/docs/current/datatype-datetime.html
		const month = parseInt(dateStr.slice(6, 8));
		const day = parseInt(dateStr.slice(9));
		// check precision
		let precision: "Y" | "MY" | "DMY" = "DMY";
		// our own estimation of precision (before 1600, it is usually years when the date is 1st january)
		if (year < 1800 && month === 1 && day === 1) precision = "Y";
		// format properly the dateStr (replace - by /)
		const dateStrReplaced = dateStr.startsWith("-")
			? "-" + dateStr.slice(1).replace(/-/g, "/")
			: dateStr.replace(/-/g, "/");
		// return
		return { date: new Date(year, month - 1, day), dateStr: dateStrReplaced, precision };
	} catch (error) {
		console.error("Converting wiki date to Date : FAILED >> " + error);
		throw ERROR_WRONG_RESPONSE;
	}
};

export const convertDataToWikiPage = (data: any): IWikiPage => {
	try {
		const id = data.id;
		const name = data.name.slice(0, MAX_LENGTH.ELEMENT_NAME);
		const otherNames =
			data.otherNames && data.otherNames.length > 0
				? (Object.values(data.otherNames).flat() as string[]).map((n) =>
						n.slice(0, MAX_LENGTH.ELEMENT_NAME)
				  )
				: [];
		const description = !!data.description ? data.description : "";
		const categories: IWikiCategory[] = data.categories ? data.categories : [];
		const categoryForPeriod = categories.some((category) =>
			WikiCategoriesForPeriod.includes(category.id)
		);
		// we filter out categories which aren't words
		const categoriesFiltered = categories.filter((c) => {
			return /^[A-Za-z]/.test(c.label);
		});
		// we can't establish the period correctly. If the category is part of the ones with period, we set period to Yes.
		// @TODO find a better way
		const dates: IDates = {
			startDate: convertWikiDateToIDate(data.dateStart),
			endDate: convertWikiDateToIDate(data.dateEnd),
			period: data.dateEnd ? true : categoryForPeriod ? true : false, // this is really not good enough
		};
		const image = data.image ? data.image : undefined;
		const sources: string[] = [data.source].concat(Object.values(data.sources).flat() as string[]);
		const tags: string[] = categoriesFiltered
			.map((c) => c.label)
			.concat(data.tags ? (Object.values(data.tags).flat() as string[]) : []);
		const relations: IWikiRelation[] = convertDataToWikiRelations(data.relations);
		const coordinates = data.coordinates ? data.coordinates : undefined;
		// return
		return {
			id,
			name,
			otherNames,
			description,
			dates,
			image,
			sources,
			tags,
			relations,
			coordinates,
		} as IWikiPage;
	} catch (error) {
		console.error("Converting data to WikiPage: FAILED >> " + error);
		console.log("Converting data to WikiPage: Source data >> ");
		console.log(JSON.stringify(data));
		throw ERROR_WRONG_RESPONSE;
	}
};

// -----------------------------------------------------------------------
// INTERVIEWS

export const convertDataToInterviewMessages = (data: any): IInterviewMessage[] => {
	try {
		const messages: IInterviewMessage[] = data.map((mess: any) => {
			return { role: mess.role, content: mess.content } as IInterviewMessage;
		});
		return messages;
	} catch (error) {
		console.error("Converting data to Interview Messages: FAILED >> " + error);
		throw ERROR_WRONG_RESPONSE;
	}
};

export const convertDataToInterview = (data: any): IInterview => {
	try {
		const id = data.id;
		const characterId = data.character_id;
		const name = data.name;
		const language = data.language;
		const tone = data.tone;
		const audienceAge = data.audience_age;
		const messages = convertDataToInterviewMessages(data.messages);
		const image = !!data.image ? HOST_API_URL + data.image : undefined;
		// tech
		const createdAt = new Date(data.created_at);
		const updatedAt = new Date(data.updated_at);
		// return
		return {
			id,
			characterId,
			name,
			language,
			tone,
			audienceAge,
			messages,
			image,
			createdAt,
			updatedAt,
		} as IInterview;
	} catch (error) {
		console.error("Converting data to Interview: FAILED >> " + error);
		throw ERROR_WRONG_RESPONSE;
	}
};
